import TripleDotIcon from 'app/assets/icons/TripleDotIcon';
import EsignAppPrimaryButton from 'app/components/Buttons/EsignAppPrimaryButton';
import EsignAppSecondaryButton from 'app/components/Buttons/EsignAppSecondaryButton';
import { FinishDropdown } from 'app/components/Dropdowns/FinishDropdown';
import DocumentSignedSuccessScreen from 'app/components/EsignSuccessScreen/DocumentSignedSuccessScree';
import ExitSignDocFlow from 'app/components/Popups/ExitSignDocFlow';
import PrimaryPopupForConfirmation from 'app/components/Popups/PrimaryPopupForConfirmation';
import { useAccount } from 'context/AccountProvider';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { createSignConfig, updateDocumentStatus } from 'services/eSignService';
import { selectCombinedSignAndUnSignedNode, selectUsersNodes, setEsignMainAppLoader } from 'store/esignMainSlice';
import { PlaceholderPayloadKeys, RecipientDropdownActionsConst } from 'utils/esign.helper';

const EsignReciepientFooterFinish = ({ setIsSignedSubmitted = () => {} }) => {
  const { userDetails } = useAccount();
  const esignSelectedSignType = useSelector((state) => state.esignMain.esignSelectedSignType);
  const usersNodes = useSelector(selectUsersNodes);
  const { placeHolderValues, publicRecipientDetails } = useSelector((state) => state.main);
  const combinedSignAndUnSignedNode = useSelector(selectCombinedSignAndUnSignedNode);
  const uploadedDocumentAllData = useSelector((state) => state.esignMain.uploadedDocumentAllData);
  const { documentId: documentIdParam } = useParams();
  const documentId = uploadedDocumentAllData?.id ? uploadedDocumentAllData?.id : uploadedDocumentAllData?._id?.$oid;
  const [showExitPopup, setShowExitPopup] = useState(false);
  const [showApproveDocPopup, setShowApproveDocPopup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFinish = async () => {
    const updatedNodes = usersNodes.map((node) => {
      const matchingItem = placeHolderValues.find((item) => node?.id === item?.id);
      const newNode = { ...node };
      if (matchingItem) {
        const { OriginalPdfImgHeight, pdfImgHeight, OriginalPdfImgWidth, pdfImgWidth, height, width } = newNode;
        const heightScaleRatio = OriginalPdfImgHeight / pdfImgHeight;
        const widthScaleRatio = OriginalPdfImgWidth / pdfImgWidth;
        const newHeight = height;
        const newWidth = width;

        return {
          ...newNode,
          [PlaceholderPayloadKeys[matchingItem?.actionType]]: matchingItem.addedValue,
          oldHeight: height,
          oldWidth: width,
          height: newHeight,
          width: newWidth
        };
      } else {
        return node;
      }
    });
    const payload = {
      sign_data: updatedNodes
    };
    console.log(updatedNodes, 'widthScaleRatio');
    const userEmail = publicRecipientDetails?.email ? publicRecipientDetails?.email : userDetails?.email;
    const newDocId = publicRecipientDetails?.id ? publicRecipientDetails?.id : documentId;
    if (userEmail) {
      try {
        dispatch(setEsignMainAppLoader(true));
        const res = await createSignConfig(documentId, userEmail, payload);
        if (res.status === 200) {
          toast.success('Document signed successfully.');

          // if (publicRecipientDetails?.email) {
          //   navigate('/');
          // } else {
          //   navigate('/dashboard/digi-esign');
          // }
          setIsSignedSubmitted(true);
        }
        console.log(payload, 'payload');
      } catch (error) {
      } finally {
        dispatch(setEsignMainAppLoader(false));
      }
    } else {
      toast.error('Signs not added. try again later.');
    }
  };
  const userEmail = publicRecipientDetails?.email ? publicRecipientDetails?.email : userDetails?.email;
  let isCompeleted = null;
  const allRequiredPlaceholderdone = combinedSignAndUnSignedNode.filter((item) => {
    if (item?.email === userEmail) {
      if (item?.objectType === 'sign_config' && isCompeleted === null) {
        isCompeleted = false;
      } else {
        if (isCompeleted === null) {
          isCompeleted = true;
        }
      }
    }
    return item?.email === userEmail;
  });
  console.log(uploadedDocumentAllData, publicRecipientDetails, 'combinedSignAndUnSignedNode');
  const renderPrimaryButton = () => {
    const recipientConfig = userDetails?.email
      ? uploadedDocumentAllData?.recipient_configs?.filter?.((item) => item?.email === userDetails?.email)?.[0] || {}
      : { type: uploadedDocumentAllData?.type, status: '' };
    if (recipientConfig?.type === RecipientDropdownActionsConst.NeedsToApprove) {
      return (
        <EsignAppPrimaryButton
          title={recipientConfig?.status !== '' ? 'Approved' : 'Approve'}
          id="esignDocumentCreateProceedButton"
          disabled={recipientConfig?.status !== ''}
          onClick={() => setShowApproveDocPopup(true)}
        />
      );
    } else {
      return (
        <EsignAppPrimaryButton
          title="Finish"
          id="esignDocumentCreateProceedButton"
          disabled={
            (!placeHolderValues.length && !allRequiredPlaceholderdone.length) ||
            allRequiredPlaceholderdone.length !== placeHolderValues.length
          }
          onClick={handleFinish}
        />
      );
    }
    console.log(recipientConfig, 'recipientConfig');
  };

  const handleApprove = async () => {
    const email = publicRecipientDetails?.email ? publicRecipientDetails?.email : userDetails?.email;
    const newDocumentId = publicRecipientDetails?.email ? documentId : uploadedDocumentAllData?.id;
    console.log(publicRecipientDetails, uploadedDocumentAllData, 'publicRecipientDetails');
    try {
      dispatch(setEsignMainAppLoader(true));
      const data = await updateDocumentStatus({
        documentId: documentId,
        userEmail: email,
        status: RecipientDropdownActionsConst.NeedsToApprove
      });
      if (data?.message) {
        toast.success(data?.message);
        setShowApproveDocPopup(false);

        navigate('/dashboard/home');
      }
      console.log(data, 'updateDocumentStatus');
    } catch (error) {
      toast.error(error.message);
    } finally {
      dispatch(setEsignMainAppLoader(false));
    }
  };
  console.log(
    placeHolderValues,
    (!placeHolderValues.length && !allRequiredPlaceholderdone.length) || allRequiredPlaceholderdone.length !== placeHolderValues.length,
    'placeHolderValuesdebug90'
  );
  return (
    <>
      {showExitPopup ? <ExitSignDocFlow onClose={() => setShowExitPopup(false)} /> : null}

      {showApproveDocPopup ? (
        <div className="finishBtnContainer">
          <PrimaryPopupForConfirmation
            title={'Approve Document '}
            description={'Are you sure you want to Approve this document?'}
            onSubmit={handleApprove}
            onCancel={() => setShowApproveDocPopup(false)}
            primaryBtnTitle="Confirm"
          />
        </div>
      ) : null}
      <div className="absolute w-[240px] bottom-[30px] right-[0px] flex items-center justify-evenly">
        {/* <EsignAppSecondaryButton title="Exit" onClick={() => setShowExitPopup(true)} /> */}

        {/* {renderPrimaryButton()} */}
      </div>
      <FinishDropdown
        setShowExitPopup={setShowExitPopup}
        handleFinish={handleFinish}
        handleApprove={() => setShowApproveDocPopup(true)}
        documentId={documentId}
        isFinishDisabled={
          (!placeHolderValues.length && !allRequiredPlaceholderdone.length) ||
          allRequiredPlaceholderdone.length !== placeHolderValues.length
        }
      />
    </>
  );
};

export default EsignReciepientFooterFinish;
