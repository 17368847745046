import styled from '@emotion/styled';
import { TableCell, TableRow, tableCellClasses } from '@mui/material';
import { fontFamily, fontWeight } from '@mui/system';
import ApprovedIcon from 'app/assets/icons/ApprovedIcon';
import SignedByYouIcon from 'app/assets/icons/SignedByYouIcon';
import { EsignNewIcon } from 'assets/images/eSign';
import { parse } from 'flatted';
import { FitIntoBoxStyleSignConst } from 'layout/MainLayoutNewUI/Esign/replaceCanvas/RightSideBar/signatures/FreeTextSign';
import { BsFillPersonFill, BsPencilSquare } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { setEsignPdfImageScaleValue, setEsignPdfPagesPosition } from 'store/esignMainSlice';
import { constrainAndRestrictMovement, constrainMovement, restrictPlaceholderGoOutsideCanvasOnMoveEvent } from './fabircCanvas.helper';
import moment from 'moment-timezone';
import { defaultTimezone } from 'context/TimezoneContext';
export const placeholderReplaceOpacity = 0.8;
export function createCustomRect(options) {
  // Define a custom shape class that extends fabric.Rect
  fabric.CustomRect = fabric.util.createClass(fabric.Rect, {
    type: 'customRect',

    initialize(options) {
      options || (options = {});
      this.callSuper('initialize', options);
      this.set('topText', options.topText || '');
      this.set('leftText', options.leftText || '');
      this.set('bottomText', options.bottomText || '');
      this.set('minFontSize', 10); // Minimum font size to prevent text from becoming too small
      this.set('cornerRadius', options.cornerRadius || 10); // Corner radius for rounded corners
      this.set('svgImage', options.svgImage || null); // SVG Image to display in the center
    },

    toObject() {
      return fabric.util.object.extend(this.callSuper('toObject'), {
        topText: this.get('topText'),
        leftText: this.get('leftText'),
        bottomText: this.get('bottomText'),
        cornerRadius: this.get('cornerRadius'), // Include cornerRadius in the object
        svgImage: this.get('svgImage') // Include SVG image in the object
      });
    },

    _fitTextToWidth(ctx, text, maxWidth) {
      let fontSize = 16; // Starting font size
      ctx.font = `${fontSize}px Arial`;

      // Reduce font size until the text fits within maxWidth
      while (ctx.measureText(text).width > maxWidth && fontSize > this.minFontSize) {
        fontSize--;
        ctx.font = `${fontSize}px Arial`;
      }

      return fontSize; // Return the adjusted font size
    },

    // Draw only left side corners and strokes
    _drawLeftSideRoundedStroke(ctx, x, y, width, height, radius) {
      ctx.beginPath();

      // Top-left corner
      ctx.moveTo(x, y + radius);
      ctx.arcTo(x, y, x + radius, y, radius); // Stroke for the top-left corner

      // Draw left side stroke without the top-right and bottom-right
      ctx.lineTo(x + radius, y); // Skip top-right corner stroke

      // Draw the stroke for the bottom-left corner
      ctx.moveTo(x + radius, y + height);
      ctx.arcTo(x, y + height, x, y + height - radius, radius); // Bottom-left corner stroke

      ctx.stroke();
    },

    _render(ctx) {
      const padding = 10; // Padding to prevent text from touching the edge
      const availableWidth = this.width - padding * 2; // Width available for text (inside the padding)

      // Draw the filled rounded rectangle
      ctx.fillStyle = this.fill;
      ctx.beginPath();
      ctx.moveTo(-this.width / 2 + this.cornerRadius, -this.height / 2);
      ctx.lineTo(this.width / 2 - this.cornerRadius, -this.height / 2);
      ctx.arcTo(this.width / 2, -this.height / 2, this.width / 2, -this.height / 2 + this.cornerRadius, this.cornerRadius);
      ctx.lineTo(this.width / 2, this.height / 2 - this.cornerRadius);
      ctx.arcTo(this.width / 2, this.height / 2, this.width / 2 - this.cornerRadius, this.height / 2, this.cornerRadius);
      ctx.lineTo(-this.width / 2 + this.cornerRadius, this.height / 2);
      ctx.arcTo(-this.width / 2, this.height / 2, -this.width / 2, this.height / 2 - this.cornerRadius, this.cornerRadius);
      ctx.lineTo(-this.width / 2, -this.height / 2 + this.cornerRadius);
      ctx.arcTo(-this.width / 2, -this.height / 2, -this.width / 2 + this.cornerRadius, -this.height / 2, this.cornerRadius);
      ctx.closePath();
      ctx.fill();

      // Draw stroke only on the left side corners
      if (this.stroke) {
        ctx.strokeStyle = this.stroke;
        ctx.lineWidth = this.strokeWidth;
        this._drawLeftSideRoundedStroke(ctx, -this.width / 2, -this.height / 2, this.width, this.height, this.cornerRadius);
      }

      // Set text styles dynamically based on the rect width
      ctx.fillStyle = 'black'; // Text color
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';

      // Fit the top text within the top border
      if (this.topText) {
        const topFontSize = this._fitTextToWidth(ctx, this.topText, availableWidth);
        ctx.font = `${topFontSize}px Arial`;
        ctx.fillText(this.topText, 0, -this.height / 2); // Centered on the top border
      }

      // Fit the bottom text within the bottom border
      if (this.bottomText) {
        const bottomFontSize = this._fitTextToWidth(ctx, this.bottomText, availableWidth);
        ctx.font = `${bottomFontSize}px Arial`;
        ctx.fillText(this.bottomText, 0, this.height / 2); // Centered on the bottom border
      }

      // Fit the left text within the left border (vertically)
      if (this.leftText) {
        ctx.save();
        ctx.translate(-this.width / 2, 0); // Position along the left side
        ctx.rotate(-Math.PI / 2); // Rotate 90 degrees to make it vertical
        const leftFontSize = this._fitTextToWidth(ctx, this.leftText, this.height - padding * 2);
        ctx.font = `${leftFontSize}px Arial`;
        ctx.fillText(this.leftText, 0, 0); // Centered on the left border
        ctx.restore();
      }

      // Draw SVG in the center if provided
      if (this.svgImage) {
        const img = new Image();
        img.src = this.svgImage;
        img.onload = () => {
          // Calculate aspect ratio
          const imgAspectRatio = img.width / img.height;
          const rectAspectRatio = this.width / this.height;

          // Determine scaled dimensions
          let imgWidth, imgHeight;

          if (imgAspectRatio > rectAspectRatio) {
            // Image is wider than the rectangle
            imgWidth = this.width * 0.8; // Use 80% of the rectangle's width
            imgHeight = imgWidth / imgAspectRatio; // Maintain aspect ratio
          } else {
            // Image is taller than the rectangle
            imgHeight = this.height * 0.8; // Use 80% of the rectangle's height
            imgWidth = imgHeight * imgAspectRatio; // Maintain aspect ratio
          }
          //   ctx.imageSmoothingEnabled = false;

          // Center the image
          ctx.drawImage(
            img,
            -imgWidth / 2,
            -imgHeight / 2,

            imgWidth,
            imgHeight
          );
        };
      }
    }
  });

  // Return an instance of the custom rectangle
  return new fabric.CustomRect({
    left: options.left || 0,
    top: options.top || 0,
    width: options.width || 200,
    height: options.height || 100,
    fill: options.fill || 'lightgray',
    stroke: options.stroke || 'black',
    strokeWidth: options.strokeWidth || 2,
    topText: options.topText || '',
    leftText: options.leftText || '',
    bottomText: options.bottomText || '',
    cornerRadius: options.cornerRadius || 10, // Set corner radius
    svgImage: options.svgImage || null // SVG image to display in the center
  });
}

const selectableObjOnlyProps = {
  evented: true, // Prevent events like mouse hover
  hasControls: false, // Hide resizing controls
  hasBorders: true, // Hide borders
  lockMovementX: true, // Lock movement along the X-axis
  lockMovementY: true, // Lock movement along the Y-axis
  lockRotation: true, // Lock rotation
  lockScalingX: true, // Lock scaling along the X-axis
  lockScalingY: true // Lock scaling along the Y-axis,
};

export const PlaceHolderTypes = {
  sign: 'signature',
  initials: 'initials',
  dateSigned: 'date',
  email: 'email',
  name: 'full_name',
  mobile: 'mobile',
  designation: 'designation',
  valuePlaced: 'valuePlaced'
};

export const PlaceholderPayloadKeys = {
  [PlaceHolderTypes.sign]: 'imgUrl',
  [PlaceHolderTypes.initials]: 'imgUrl',
  [PlaceHolderTypes.dateSigned]: 'imgUrl',
  [PlaceHolderTypes.email]: 'imgUrl'
};

export const DragdropActionConst = [
  {
    name: 'Signature',
    height: 35,
    width: 84,
    color: '#E9F8C9',
    icon: <BsPencilSquare />,
    type: PlaceHolderTypes.sign,
    newIcon: EsignNewIcon.SignPenIcon
  },
  // {
  //   name: 'Full Name',
  //   height: 35,
  //   width: 84,
  //   color: '#E9F8C9',
  //   icon: <BsPencilSquare />,
  //   type: PlaceHolderTypes.name,
  //   newIcon: EsignNewIcon.InitialsIcon
  // },
  {
    name: 'Initials',
    height: 35,
    width: 100,
    color: '#ffd65b',
    // icon: <BsFillPersonFill />,
    type: PlaceHolderTypes.initials,
    newIcon: EsignNewIcon.InitialsIcon
  },
  {
    name: 'Date Signed',
    height: 35,
    width: 100,
    color: '#E9F8C9',
    icon: <BsFillPersonFill />,
    type: PlaceHolderTypes.dateSigned,
    newIcon: EsignNewIcon.DateSignedIcon
  }
  // {
  //   name: 'Email',
  //   height: 35,
  //   width: 100,
  //   color: '#ffd65b',
  //   icon: <BsFillPersonFill />,
  //   type: PlaceHolderTypes.email
  // }
];

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    borderLeft: 0
  },
  'td, th ': {
    border: 0,
    borderRight: '1px solid #0000000D !important'
  }
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

export const convertObjectIdToDate = (timestamp, isWithSecond) => {
  const date = new Date(timestamp);

  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1; // Adding 1 because getMonth() returns 0-based index
  const year = date.getFullYear() % 100 < 10 ? '0' + (date.getFullYear() % 100) : date.getFullYear() % 100;
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine if it's AM or PM
  const period = hours >= 12 ? 'pm' : 'am';

  // Convert to 12-hour clock format
  hours = hours % 12 || 12;
  if (isWithSecond) {
    return { date: `${day}/${month}/${year}`, time: `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes} ${period}` };
  }

  return `${day}/${month}/${year} : ${hours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
};

export const TABLE_HEAD = [
  { id: 'document_name', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  {
    id: 'updated_at',
    label: 'Last Updated',
    alignRight: false
  },
  { id: '', label: '', alignRight: false }
];

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_) => _.document_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export const DocPlaceHolderTypes = {
  sign: 'sign',
  fullName: 'fullName'
};

// const restrictPlaceholderGoOutsideCanvasOnMoveEvent = (options, canvas) => {
//   const { e } = options;
//   const obj = options.transform?.target; // Get the object being transformed (moved)

//   // Ensure the object exists
//   if (!obj) return;

//   // Get mouse pointer position relative to the canvas
//   const mouse = canvas.getPointer(e);

//   // Calculate new left and top positions based on mouse movement
//   obj.set({
//     left: mouse.x - (obj.originalMouseX || 0),
//     top: mouse.y - (obj.originalMouseY || 0)
//   });

//   // Restrict the object within the canvas boundaries
//   const objWidth = obj.width * obj.scaleX;
//   const objHeight = obj.height * obj.scaleY;

//   // Restrict the left and top positions within canvas
//   if (obj.left < 0) obj.left = 0;
//   if (obj.top < 0) obj.top = 0;

//   // Restrict the right and bottom positions within canvas
//   if (obj.left + objWidth > canvas.width) {
//     obj.left = canvas.width - objWidth;
//   }
//   if (obj.top + objHeight > canvas.height) {
//     obj.top = canvas.height - objHeight;
//   }

//   // Ensure the changes are rendered
//   obj.setCoords();
//   canvas.renderAll();
// };

// Helper function to check if an object is in the space between pages
const isInPaddingArea = (obj, pageBoundaries, paddingBottom) => {
  return pageBoundaries.some((boundary, index) => {
    if (index < pageBoundaries.length - 1) {
      const nextBoundary = pageBoundaries[index + 1];
      return obj.top >= boundary.bottom && obj.top <= nextBoundary.top - paddingBottom;
    }
    return false;
  });
};
export const addPlaceholderBox = (canvas, e, uniqueId, color, email) => {
  const dropItem = parse(e.dataTransfer.getData('customData'));
  console.log(dropItem, 'usePlaceholderDropOnCanvas');
  const { selected } = dropItem;
  if (selected) {
    const rect = new fabric.Rect({
      // left: e.layerX - 80,
      // top: e.layerY - 40,
      width: selected?.width,
      height: selected?.height,
      fill: color ? color : selected?.color,
      // rx: 10,
      // ry: 10,
      stroke: '#D1D9E0',
      strokeWidth: 2,
      opacity: 0.5
      // selectable: true,
      // hasRotatingPoint: false, // Enable rotation handle
      // lockRotation: true, // Lock rotation
      // lockUniScaling: true, // Lock uniform scaling
      // lockScalingFlip: true, // Lock scaling flip
      // borderColor: 'black', // Border color
      // cornerColor: 'blue', // Corner color
      // cornerSize: 10, // Corner size
      // transparentCorners: false, // Disable transparent corners
      // resizable: true // Make the rectangle resizable
    });

    // Add text to the rectangle
    const text = new fabric.Text(selected.name, {
      fontSize: 15,
      fill: 'black',
      selectable: false, // Text should not be selectable independently,
      fontFamily: 'Inter',
      fontWeight: 600
    });
    // Center {}the text within the rectangle
    const textLeft = rect.left + (rect.width - text.width) / 2;
    const textTop = rect.top + (rect.height - text.height) / 2;
    text.set({
      left: textLeft,
      top: textTop
    });

    var zoomLevel = canvas.getZoom();
    var canvasX = e.layerX / zoomLevel - canvas.viewportTransform[4] / zoomLevel;
    var canvasY = e.layerY / zoomLevel - canvas.viewportTransform[5] / zoomLevel;

    // Create a group to contain both the rectangle and the text
    const group = new fabric.Group([rect, text], {
      selectable: true, // Allow selection of the group
      id: uniqueId,
      hasRotatingPoint: false,
      hasControls: false,
      lockRotation: true,
      hasBorders: true,
      resizable: true,
      left: canvasX,
      top: canvasY,
      width: selected?.width,
      height: selected?.height,
      actionType: PlaceHolderTypes.valuePlaced,
      placeholderItemActionType: dropItem.selected.type,
      email: email
      // lockScalingX: true,
      // lockScalingY: true
    });
    group.setControlsVisibility({
      mt: false, // top-middle
      mb: false, // bottom-middle
      ml: false, // middle-left
      mr: false, // middle-right
      mtr: false
    });
    canvas.add(group);

    // Enable drag and drop for the group
    group.set({
      hasControls: true // Hide controls during drag
      // lockRotation: true,
    });

    group.on('mousedown', function (options) {
      const { e } = options;
      const mouse = canvas.getPointer(e);
      const offsetX = mouse.x - group.left;
      const offsetY = mouse.y - group.top;
      group.set({
        originalMouseX: offsetX,
        originalMouseY: offsetY
      });
    });

    group.on('mouseup', function () {
      group.setCoords();
    });
    group.on('scaling', () => {
      const minHeight = 55;
      const maxHeight = 70;

      const minWidth = 168;
      const maxWidth = minWidth * 2;

      // Get the current scale and dimensions
      const groupHeight = group.getScaledHeight();
      const groupWidth = group.getScaledWidth();
      const scaleX = group.scaleX;
      const scaleY = group.scaleY;

      // Restrict height scaling
      if (groupHeight < minHeight) {
        group.scaleY = minHeight / group.height; // Reset to minimum height
      }

      if (groupHeight > maxHeight) {
        group.scaleY = maxHeight / group.height; // Reset to maximum height
      }

      // Restrict width scaling
      if (groupWidth < minWidth) {
        group.scaleX = minWidth / group.width; // Reset to minimum width
      }

      if (groupWidth > maxWidth) {
        group.scaleX = maxWidth / group.width; // Reset to maximum width
      }

      // Optionally, update the coordinates to ensure the group stays within bounds
      group.setCoords();
      canvas.renderAll();
    });

    // group.on('moving', function (options) {
    //   const { e } = options;
    //   const mouse = canvas.getPointer(e);
    //   group.set({
    //     left: mouse.x - group.originalMouseX,
    //     top: mouse.y - group.originalMouseY
    //   });
    // });
    // canvas.setActiveObject(group);
    // group.on('moving', (e) => restrictPlaceholderGoOutsideCanvasOnMoveEvent(e, canvas));
    // canvas.setActiveObject(group);

    return group;
  } else {
    console.error('dropItem should not be empty');
  }
};

// export const loadPdfOnCanvas = (uploadDocument, canvas) => {
//   console.log(uploadDocument, canvas, 'canvasData');
//   if (Array.isArray(uploadDocument) && canvas !== null) {
//     let canvasWidth = 0;
//     let canvasHeight = 0;
//     let top = 0;
//     //'https://craftmypdf.com/wp-content/uploads/2021/10/mp_06.png'
//     uploadDocument.map((item) => {
//       fabric.Image.fromURL(item?.url, function (img) {
//         const scale = 0.81;
//         img.set({
//           width: img.width,
//           height: img.height,
//           top,
//           selectable: false,
//           evented: false,
//           left: 0,
//           scaleX: scale,
//           scaleY: scale
//         });
//         top += img.height * scale;
//         canvasWidth = img.width * scale;
//         canvasHeight += img.height * scale;
//         canvas.setDimensions({ width: canvasWidth - 5, height: canvasHeight });
//         canvas.add(img);
//         canvas.renderAll();
//       });
//     });
//   } else {
//     console.error('Failed to load pdf');
//   }
// };

export const loadPdfOnCanvasForSignCanvas = (uploadDocument, canvas, dispatch) => {
  console.log(uploadDocument, canvas, 'canvasData');
  // canvas.set({
  //   left: 0,
  //   top: 0
  // });
  // canvas.setZoom(1);
  if (Array.isArray(uploadDocument) && canvas !== null) {
    let canvasHeight = 0;
    const canvasWidth = canvas.width;
    const positions = [];
    let currentTop = 0;
    let paddingBottom = 20;
    uploadDocument.forEach((item, i) => {
      fabric.Image.fromURL(
        item?.url,
        (img) => {
          const scaleFactor = canvasWidth / img.width;

          const scaledWidth = img.width * scaleFactor;
          const scaledHeight = Math.floor(img.height * scaleFactor);
          dispatch(setEsignPdfImageScaleValue(scaleFactor));
          console.log(scaledHeight, img.height, scaleFactor, currentTop, 'scaledHeight');
          // Ensure image fits within canvas width while maintaining aspect ratio
          img.scaleToWidth(canvasWidth);

          img.set({
            top: currentTop,
            selectable: false,
            evented: false,
            left: 0,
            scaleFactor
          });

          canvas.add(img);
          positions.push(currentTop);
          currentTop += scaledHeight + paddingBottom;
          dispatch(setEsignPdfPagesPosition([...positions]));
          canvasHeight += scaledHeight; // Add padding between images
          canvas.setHeight(canvasHeight);
          // canvas.setWidth(scaledWidth);
          canvas.sendToBack(img);
          canvas.renderAll();
          if (i === uploadDocument.length - 1) {
            let container = document.querySelector('.drag-area');
            container.dataset.dynamicHeight = canvasHeight;
            container.dataset.dynamicWidth = canvasWidth;
          }
        },
        { crossOrigin: 'anonymous' }
      ); // Handle CORS issues for image loading
    });
  } else {
    console.error('Failed to load images to canvas.');
  }
};

export const loadPdfOnCanvasForPlaceholderCanvas = (uploadDocument, canvas, dispatch) => {
  console.log(uploadDocument, canvas, 'canvasData');

  if (Array.isArray(uploadDocument) && canvas !== null) {
    let canvasHeight = 0;
    const canvasWidth = canvas.width;
    const positions = [];
    let currentTop = 0;
    let paddingBottom = 20; // Space between pages

    // Define page boundaries array to track the start and end of each page
    const pageBoundaries = [];

    uploadDocument.forEach((item, i) => {
      fabric.Image.fromURL(
        item?.url,
        (img) => {
          const scaleFactor = canvasWidth / img.width;
          const scaledWidth = img.width * scaleFactor;
          const scaledHeight = Math.floor(img.height * scaleFactor);
          dispatch(setEsignPdfImageScaleValue(scaleFactor));

          img.scaleToWidth(canvasWidth);

          img.set({
            top: currentTop,
            selectable: false,
            evented: false,
            left: 0,
            scaleFactor
          });

          canvas.add(img);

          // Push page boundaries to the array (start and end positions)
          pageBoundaries.push({
            top: currentTop,
            bottom: currentTop + scaledHeight
          });

          positions.push(currentTop);
          currentTop += scaledHeight + paddingBottom;
          dispatch(setEsignPdfPagesPosition([...positions]));
          canvasHeight += scaledHeight + paddingBottom; // Add padding between images
          canvas.setHeight(canvasHeight);
          canvas.renderAll();

          if (i === uploadDocument.length - 1) {
            let container = document.querySelector('.drag-area');
            container.dataset.dynamicHeight = canvasHeight;
            container.dataset.dynamicWidth = canvasWidth;
          }
        },
        { crossOrigin: 'anonymous' }
      );
    });

    // Restrict object movement inside canvas and page boundaries
    // 14-10-2024 commented before overlapping

    // Helper function to check if an object is in the space between pages

    // Attach the combined movement restriction to canvas events
    canvas.on('object:moving', (e) => {
      // restrictPlaceholderGoOutsideCanvasOnMoveEvent(canvas, e);
      const obj = e.transform?.target;
      console.log(obj, 'objData');
      constrainAndRestrictMovement(obj, canvas, e, pageBoundaries, paddingBottom);
      // constrainMovement(obj, canvas);
      canvas?.renderAll();
    });
  } else {
    console.error('Failed to load images to canvas.');
  }
};

export const loadPdfOnCanvas = (uploadDocument, canvas, dispatch) => {
  console.log(uploadDocument, canvas, 'canvasData');

  if (Array.isArray(uploadDocument) && canvas !== null) {
    let canvasHeight = 0;
    const canvasWidth = canvas.width;
    const positions = [];
    let currentTop = 0;
    let paddingBottom = 20; // Space between pages

    // Define page boundaries array to track the start and end of each page
    const pageBoundaries = [];

    uploadDocument.forEach((item, i) => {
      fabric.Image.fromURL(
        item?.url,
        (img) => {
          const scaleFactor = canvasWidth / img.width;
          const scaledWidth = img.width * scaleFactor;
          const scaledHeight = Math.floor(img.height * scaleFactor);
          dispatch(setEsignPdfImageScaleValue(scaleFactor));

          img.scaleToWidth(canvasWidth);

          img.set({
            top: currentTop,
            selectable: false,
            evented: false,
            left: 0,
            scaleFactor
          });

          canvas.add(img);

          // Push page boundaries to the array (start and end positions)
          pageBoundaries.push({
            top: currentTop,
            bottom: currentTop + scaledHeight
          });

          positions.push(currentTop);
          currentTop += scaledHeight + paddingBottom;
          dispatch(setEsignPdfPagesPosition([...positions]));
          canvasHeight += scaledHeight + paddingBottom; // Add padding between images
          canvas.setHeight(canvasHeight);
          canvas.renderAll();

          if (i === uploadDocument.length - 1) {
            let container = document.querySelector('.drag-area');
            container.dataset.dynamicHeight = canvasHeight;
            container.dataset.dynamicWidth = canvasWidth;
          }
        },
        { crossOrigin: 'anonymous' }
      );
    });

    // Restrict object movement inside canvas and page boundaries
    const restrictPlaceholderGoOutsideCanvasOnMoveEvent = (options) => {
      const { e } = options;
      const obj = options.transform?.target; // Get the object being moved

      // Ensure the object exists
      if (!obj) return;

      // Get mouse pointer position relative to the canvas
      const mouse = canvas.getPointer(e);

      // Calculate new left and top positions based on mouse movement
      obj.set({
        left: mouse.x - (obj.originalMouseX || 0),
        top: mouse.y - (obj.originalMouseY || 0)
      });

      // Restrict the object within the canvas boundaries
      const objWidth = obj.width * obj.scaleX;
      const objHeight = obj.height * obj.scaleY;

      // Restrict left and top positions within canvas
      if (obj.left < 0) obj.left = 0;
      if (obj.top < 0) obj.top = 0;

      // Restrict right and bottom positions within canvas
      if (obj.left + objWidth > canvas.width) {
        obj.left = canvas.width - objWidth;
      }
      if (obj.top + objHeight > canvas.height) {
        obj.top = canvas.height - objHeight;
      }

      // Handle page boundaries
      const objTop = obj.top;
      const objBottom = objTop + objHeight;

      // Check if the object overlaps any page boundaries
      const overlappingBoundary = pageBoundaries.find((boundary) => objTop < boundary.bottom && objBottom > boundary.top);

      // If the object is between pages or inside the padding, reset it to the last valid position
      if (!overlappingBoundary || isInPaddingArea(obj, pageBoundaries, paddingBottom)) {
        const boundary = overlappingBoundary || pageBoundaries.find((b) => objTop >= b.top && objTop <= b.bottom);

        if (boundary) {
          // Restrict movement within the current page's boundary
          obj.set({
            top: Math.min(Math.max(objTop, boundary.top), boundary.bottom - objHeight)
          });
        }
      }

      // Ensure the changes are rendered
      obj.setCoords();
      canvas.renderAll();
    };

    // Helper function to check if an object is in the space between pages
    const isInPaddingArea = (obj, pageBoundaries, padding) => {
      return pageBoundaries.some((boundary, index) => {
        if (index < pageBoundaries.length - 1) {
          const nextBoundary = pageBoundaries[index + 1];
          return obj.top >= boundary.bottom && obj.top <= nextBoundary.top - padding;
        }
        return false;
      });
    };

    // Attach the combined movement restriction to canvas events
    canvas.on('object:moving', (e) => restrictPlaceholderGoOutsideCanvasOnMoveEvent(e));
  } else {
    console.error('Failed to load images to canvas.');
  }
};

export const addSignRectBox = (canvas, nodeData, currentLoggedInUserEmail, isWithFullControl = false) => {
  console.log(canvas, nodeData, currentLoggedInUserEmail, 'currentLoggedInUserEmail');
  if (nodeData) {
    const scaleX = nodeData.scaleX;
    const scaleY = nodeData.scaleY;
    const rect = new fabric.Rect({
      left: nodeData.x,
      top: nodeData.y,
      // width: nodeData?.width * scaleX,
      // height: nodeData?.height * scaleY,
      width: nodeData?.width,
      height: nodeData?.height,
      fill: nodeData?.color,
      selectable: false,
      evented: false,
      // rx: 20,
      // ry: 20,
      stroke: '#D1D9E0',
      strokeWidth: 2,
      opacity: 0.5
    });

    // Add text to the rectangle
    const text = new fabric.Text(nodeData.name, {
      fontSize: 15 * scaleX,
      fill: 'black',
      selectable: false, // Text should not be selectable independently
      evented: false,
      fontFamily: 'Inter',
      fontWeight: 600
    });

    // Center the text within the rectangle
    const textLeft = rect.left + (rect.width - text.width) / 2;
    const textTop = rect.top + (rect.height - text.height) / 2;
    text.set({
      left: textLeft,
      top: textTop
    });

    // Create a group to contain both the rectangle and the text
    const disabledSelection = currentLoggedInUserEmail
      ? currentLoggedInUserEmail !== nodeData?.email
        ? { selectable: false, evented: false }
        : {}
      : {};
    console.log(disabledSelection, currentLoggedInUserEmail, nodeData?.email, 'disabledSelection');
    const group = new fabric.Group([rect, text], {
      selectable: true, // Allow selection of the group
      id: nodeData.id,
      left: nodeData.x,
      top: nodeData.y,
      // width: nodeData?.width * scaleX,
      // height: nodeData?.height * scaleY,
      width: nodeData?.width,
      height: nodeData?.height,
      actionType: nodeData?.type,
      ...disabledSelection
    });

    canvas.add(group);

    // Enable drag and drop for the group
    if (!isWithFullControl) {
      group.set({
        selectable: true,
        evented: true, // Prevent events like mouse hover
        hasControls: false, // Hide resizing controls
        hasBorders: true, // Hide borders
        lockMovementX: true, // Lock movement along the X-axis
        lockMovementY: true, // Lock movement along the Y-axis
        lockRotation: true, // Lock rotation
        lockScalingX: true, // Lock scaling along the X-axis
        lockScalingY: true, // Lock scaling along the Y-axis
        ...disabledSelection
      });
    } else {
      group.setControlsVisibility({
        mt: false, // top-middle
        mb: false, // bottom-middle
        ml: false, // middle-left
        mr: false, // middle-right
        mtr: false
      });
    }

    // canvas.bringToFront(group);
    // console.log(group, group?.bringToFront, 'groupData');
    // canvas.renderAll();
    return group;
  } else {
    console.error('dropItem should not be empty');
  }
};

// export const addSignImage = (item, canvas) => {
//   const { x, y, width, height, id, imgUrl } = item;
//   const scaleX = 1;
//   const scaleY = 1;
//   console.log(item, 'debugdatanew');
//   // Create a new image object and add it to the canvas
//   fabric.Image.fromURL(imgUrl, (img) => {
//     // img.scaleToWidth(width);
//     // img.scaleToHeight(height);
//     img.set({
//       id,
//       // left: x + (width - img.width) / 2,
//       // top: y,
//       left: x,
//       top: y,
//       selectable: false,
//       height: 100,
//       width: 100
//     });
//     canvas.add(img);
//     canvas.renderAll();
//   });
// };
///**** Commented on 04-09-2024 */

export const addSignImage = (item, canvas) => {
  const {
    x,
    y,
    oldWidth: width,
    oldHeight: height,
    id,
    imgUrl,
    OriginalPdfImgHeight,
    pdfImgHeight,
    OriginalPdfImgWidth,
    pdfImgWidth,
    scaleX,
    scaleY
  } = item;

  // Create a new image object and add it to the canvas
  fabric.Image.fromURL(imgUrl, (img) => {
    // Set the image to the desired dimensions (using scaleX and scaleY)
    img.set({
      id,
      left: x,
      top: y,
      selectable: false
    });

    // Scale the image to the desired width and height
    // const heightScaleRatio = OriginalPdfImgHeight / pdfImgHeight;
    // const widthScaleRatio = OriginalPdfImgWidth / pdfImgWidth;
    // const newHeight = height * heightScaleRatio;
    // const newWidth = width * widthScaleRatio;
    // const scaleXNew = width / img.width / widthScaleRatio;
    // const scaleYNew = height / img.height / heightScaleRatio;
    const scaleXNew = width / img.width;
    const scaleYNew = height / img.height;
    img.set({
      scaleX: scaleXNew || 1, // Scale in X-axis to match the width
      scaleY: scaleYNew || 1 // Scale in Y-axis to match the height
    });
    console.log(img, width, img.width, item, 'img');
    // Add the image to the canvas and render
    canvas.add(img);
    canvas.renderAll();
  });
};

// export const replacePlaceholderwithImage = (url, canvas, cb) => {
//   const selectedObjects = canvas.getActiveObjects();

//   if (selectedObjects.length === 0) return;

//   // Get properties of the selected object
//   const { left, top, width, height, scaleX, scaleY, id, getBoundingRect } = selectedObjects[0];

//   fabric.Image.fromURL(url, (img) => {
//     // img.scaleToWidth(width);
//     // img.scaleToHeight(height);
//     const boundingRect = selectedObjects[0]?.getBoundingRect();
//     const imgBoundingRect = img?.getBoundingRect();

//     console.log(width, height, boundingRect, imgBoundingRect, 'replace sign');
//     img.set({
//       id,
//       left: boundingRect?.left,
//       top: boundingRect?.top,
//       ...selectableObjOnlyProps,
//       actionType: PlaceHolderTypes.valuePlaced,
//       scaleX: boundingRect.width / imgBoundingRect.width,
//       scaleY: boundingRect.height / imgBoundingRect.height,
//       originX: 'left',
//       originY: 'top'
//     });
//     canvas.add(img);
//     canvas.remove(...selectedObjects);
//     console.log(
//       selectedObjects,
//       img,
//       width,
//       height,
//       img.width,
//       img.height,
//       left + (177.74079389941855 - 119.44945826573829) / 3,
//       'debug 2'
//     );
//     cb(img);
//     canvas.renderAll();
//   });
// };

///********************************** */

//with timestamp and tex along with sign
// export const replacePlaceholderwithImage = (url, canvas, cb) => {
//   const selectedObjects = canvas.getActiveObjects();
//   if (selectedObjects.length === 0) return;

//   // Get properties of the selected object
//   const { left, top, width, height, id } = selectedObjects[0];

//   // Load the image from the provided URL
//   fabric.Image.fromURL(url, (img) => {
//     // Calculate the maximum height for the image within the group
//     const groupHeight = height;

//     // Calculate the height for the image (70% of group height) and text area (30% of group height)
//     const imageHeight = groupHeight * 0.7;
//     const textHeight = groupHeight * 0.3;

//     // Calculate scaling factor for the image to fit within its allocated height
//     const scaleFactor = imageHeight / img.height;

//     // Scale the image to fit within its allocated height
//     const scaledWidth = img.width * scaleFactor;
//     const scaledHeight = img.height * scaleFactor;

//     // Set the image properties
//     img.set({
//       left: left + (width - scaledWidth) / 2,
//       top: top + (imageHeight - scaledHeight) / 2,
//       scaleX: scaleFactor,
//       scaleY: scaleFactor,
//       selectable: false
//     });

//     // Create a rectangle to represent the text area
//     const textArea = new fabric.Rect({
//       left: left,
//       top: top + imageHeight,
//       width: width,
//       height: textHeight,
//       fill: 'yellow', // Background color of the text area (adjust as needed)
//       selectable: false
//     });
//     const texts = ['E-sign through lexops', new Date().toLocaleString()];
//     const textGroup = alignTextInGroup(
//       new fabric.Group([], {
//         left: left,
//         top: top + imageHeight,
//         width: width,
//         height: textHeight
//       }),
//       texts
//     );
//     // textGroup.addWithUpdate(textArea);

//     // Create a fabric.js group containing the image and text area
//     const group = new fabric.Group([img, textGroup], {
//       left,
//       top,
//       width,
//       height: groupHeight, // Set the group height to match the original selected object's height
//       id
//     });

//     // Add the group to the canvas and remove the original selected object
//     canvas.add(group);
//     canvas.remove(...selectedObjects);
//     canvas.renderAll();

//     // Execute the callback with the created group
//     cb(group);
//   });
// };
const replaceSamePlaceholderValueSameType = (canvas, cb) => {
  const selectedObjects = canvas.getActiveObjects();
  const allObject = canvas.getObjects();
  console.log(allObject, 'allObject');
  if (selectedObjects?.[0]?.placeholderItemActionType) {
    const allCurrentTypeObjects = allObject?.filter(
      (item) => item?.placeholderItemActionType === selectedObjects[0]?.placeholderItemActionType
    );
    console.log(allObject, 'allObjectdebu90');
    allCurrentTypeObjects.forEach((item) => {
      cb(item);
    });
  } else {
    const allCurrentTypeObjects = allObject?.filter((item) => item?.actionType === selectedObjects[0]?.actionType);
    console.log(allObject, 'allObjectdebu90');
    allCurrentTypeObjects.forEach((item) => {
      cb(item);
    });
  }
};
export const replacePlaceholderwithImage = (url, canvas, cb) => {
  const selectedObjects = canvas.getActiveObjects();
  if (selectedObjects.length === 0) return;
  replaceSamePlaceholderValueSameType(canvas, (item) => {
    const { left, top, width, height, scaleX, scaleY, id, getBoundingRect, email } = item;

    fabric.Image.fromURL(url, (img) => {
      // img.scaleToWidth(width);
      // img.scaleToHeight(height);
      const boundingRect = item?.getBoundingRect();
      const imgBoundingRect = img?.getBoundingRect();

      console.log(width, height, boundingRect, imgBoundingRect, 'replace sign');
      img.set({
        id,
        email,
        left: boundingRect?.left,
        top: boundingRect?.top,
        ...selectableObjOnlyProps,
        actionType: PlaceHolderTypes.valuePlaced,
        scaleX: boundingRect.width / imgBoundingRect.width,
        scaleY: boundingRect.height / imgBoundingRect.height,
        originX: 'left',
        originY: 'top',
        opacity: placeholderReplaceOpacity
      });
      canvas.add(img);
      canvas.remove(item);
      const enhancedImageResolution = img.toDataURL({ multiplier: 20 });
      cb(img, enhancedImageResolution);
      canvas.renderAll();
    });
  });
};
export const replacePlaceholderwithInitials = (url, canvas, cb) => {
  const selectedObjects = canvas.getActiveObjects();
  if (selectedObjects.length === 0) return;

  replaceSamePlaceholderValueSameType(canvas, (item) => {
    const { left, top, width, height, scaleX, scaleY, id, getBoundingRect, email } = item;

    fabric.Image.fromURL(url, (img) => {
      // Get bounding rectangle of the placeholder object
      const boundingRect = item?.getBoundingRect();

      // Get bounding box of the image once it's loaded
      const imgBoundingRect = img?.getBoundingRect();

      console.log(width, height, boundingRect, imgBoundingRect, 'replace sign');

      // Calculate the scale to maintain the aspect ratio
      const scaleRatioX = boundingRect.width / imgBoundingRect.width;
      const scaleRatioY = boundingRect.height / imgBoundingRect.height;

      // Maintain the image's aspect ratio by scaling it
      const scaleRatio = Math.min(scaleRatioX, scaleRatioY); // Choose the smaller scale ratio to maintain the aspect ratio

      img.set({
        id,
        email,
        left: boundingRect.left + (boundingRect.width - imgBoundingRect.width * scaleRatio) / 2, // Center the image horizontally
        top: boundingRect.top + (boundingRect.height - imgBoundingRect.height * scaleRatio) / 2, // Center the image vertically
        scaleX: scaleRatio, // Apply the calculated scale
        scaleY: scaleRatio,
        originX: 'left',
        originY: 'top',
        actionType: PlaceHolderTypes.valuePlaced,
        ...selectableObjOnlyProps, // Keep the original properties for the object (e.g., selectable)
        opacity: placeholderReplaceOpacity
      });

      canvas.add(img);
      canvas.remove(item);

      // Generate a high-resolution data URL for the image
      const enhancedImageResolution = img.toDataURL({ multiplier: 20 });

      // Call the callback function with the image and its high-res data URL
      cb(img, enhancedImageResolution);

      canvas.renderAll();
    });
  });
};

export const alignTextInGroup = (existingGroup, texts) => {
  if (!existingGroup || !Array.isArray(texts) || texts.length === 0) {
    return null; // Return early if group or texts are not provided correctly
  }

  // Get properties of the existing group
  const { left, top, width, height } = existingGroup;

  // Calculate font size based on the group dimensions and number of text items
  const maxFontSize = 40; // Maximum font size (adjust as needed)
  const minFontSize = 12; // Minimum font size (adjust as needed)
  const numTexts = texts.length;

  // Determine the optimal font size to fit text within the group height
  let fontSize = maxFontSize; // Start with the maximum font size
  const textHeightLimit = height / numTexts; // Evenly distribute text vertically within the group

  // Calculate font size dynamically based on the group height and number of texts
  while (fontSize > minFontSize) {
    const textObjects = texts.map((text) => new fabric.Text(text, { fontSize }));
    const totalTextHeight = textObjects.reduce((totalHeight, textObj) => totalHeight + textObj.height, 0);

    if (totalTextHeight < height * 0.8) {
      break; // Found a suitable font size
    }

    fontSize--; // Decrease font size and check again
  }

  // Create fabric.js text objects aligned within the existing group
  const textObjects = texts.map((text, index) => {
    return new fabric.Text(text, {
      fontSize,
      left: left, // Align text to the left within the group
      top: top + textHeightLimit * index, // Distribute text vertically within the group
      selectable: false,
      fontWeight: 'bold',
      fontFamily: 'Verdana'
    });
  });

  // Create a fabric.js group containing the text objects
  const newTextGroup = new fabric.Group(textObjects, {
    left,
    top,
    width,
    height,
    selectable: false
  });

  return newTextGroup;
};

export const replacePlaceholderwithText = (canvas, nodeData) => {
  if (nodeData) {
    const { scaleX, scaleY, x, y, width, height, name, id, type } = nodeData;
    // const scaleX = nodeData.scaleX;
    // const scaleY = nodeData.scaleY;
    const rect = new fabric.Rect({
      left: x,
      top: y,
      width: width * scaleX,
      height: height * scaleY,
      fill: 'transparent',
      selectable: false,
      evented: false
    });

    // Add text to the rectangle
    const text = new fabric.Text(name, {
      fontSize: 15 * scaleX,
      fill: 'black',
      selectable: false, // Text should not be selectable independently
      evented: false,
      textAlign: 'center'
    });

    // Function to resize text to fit within the rectangle
    const fitText = () => {
      const maxWidth = rect.width - 0; // Allow some padding
      const maxHeight = rect.height - 0; // Allow some padding

      let fontSize = 15 * scaleX; // Starting font size
      text.set({ fontSize }); // Set initial font size

      // Decrease font size until text fits within the rectangle
      while (text.width * scaleX > maxWidth || text.height * scaleY > maxHeight) {
        fontSize -= 1;
        text.set({ fontSize });
      }

      // Center the text within the rectangle
      const textLeft = rect.left + (rect.width - text.width * scaleX) / 2;
      const textTop = rect.top + (rect.height - text.height * scaleY) / 2;
      text.set({ left: textLeft, top: textTop });
    };

    // Resize text to fit
    fitText();

    // Create a group to contain both the rectangle and the text
    const group = new fabric.Group([rect, text], {
      selectable: false, // Allow selection of the group
      id: id,
      left: x,
      top: y,
      width: width * scaleX,
      height: height * scaleY,
      actionType: type
    });

    canvas.add(group);

    // Enable drag and drop for the group
    group.set({
      selectable: false,
      evented: false, // Prevent events like mouse hover
      hasControls: false, // Hide resizing controls
      hasBorders: true, // Hide borders
      lockMovementX: true, // Lock movement along the X-axis
      lockMovementY: true, // Lock movement along the Y-axis
      lockRotation: true, // Lock rotation
      lockScalingX: true, // Lock scaling along the X-axis
      lockScalingY: true // Lock scaling along the Y-axis
    });
    // canvas.bringToFront(group);
    // console.log(group, group?.bringToFront, 'groupData');
    // canvas.renderAll();
    return group;
  }
};

export function getFormattedInitials(firstName, lastName) {
  // Get the first character of first_name
  const firstInitial = firstName.charAt(0);

  // Check if last_name is available
  if (lastName && lastName.trim() !== '') {
    // Get the first character of last_name
    const lastInitial = lastName.charAt(0);
    return `${firstInitial}.${lastInitial}`;
  } else {
    // If last_name is not available, return the first two characters of first_name with a dot between them
    const firstTwoChars = firstName.slice(0, 2);
    return firstTwoChars.split('').join('.');
  }
}

export function getRandomLightColor() {
  // Random hue (between 0 and 360 degrees)
  const hue = Math.floor(Math.random() * 360);

  // Fixed saturation and lightness values for light colors
  const saturation = 50; // 50% saturation (medium saturation)
  const lightness = Math.floor(Math.random() * 25) + 70; // Lightness between 70% and 95%

  // Convert HSL values to RGB
  const rgbColor = hslToRgb(hue, saturation, lightness);

  // Convert RGB values to hex format
  const hexColor = rgbToHex(rgbColor[0], rgbColor[1], rgbColor[2]);

  return hexColor;
}

// Helper function to convert HSL to RGB
function hslToRgb(h, s, l) {
  h /= 360;
  s /= 100;
  l /= 100;
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // Achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

// Helper function to convert RGB to hex format
function rgbToHex(r, g, b) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}
export function combineSignatures(signConfig, signs) {
  const combinedArray = [];

  // Process entries from sign_config
  Object.entries(signConfig).forEach(([email, signaturesFromConfig]) => {
    const signaturesFromSigns = signs?.length ? signs : signs[email] || [];

    // Filter out signatures from sign_config that have the same ID in signs
    const filteredSignaturesFromConfig = signaturesFromConfig.filter((configSignature) => {
      const isDuplicate = signaturesFromSigns.some((signsSignature) => signsSignature.id === configSignature.id);
      return !isDuplicate;
    });

    // Combine filtered signatures from sign_config and signatures from signs
    const combinedSignatures = filteredSignaturesFromConfig.map((signature) => ({
      ...signature,
      objectType: 'sign_config'
    }));

    // Add objectType to signatures from signs and merge into combinedSignatures
    signaturesFromSigns.forEach((signature) => {
      combinedSignatures.push({
        ...signature,
        objectType: 'signs'
      });
    });

    // Push combined signatures into the final array
    combinedArray.push(...combinedSignatures);
  });

  return combinedArray;
}

export const getFullNameFirstWord = (firstName, lastName) => {
  let word = firstName ? firstName[0] : '';
  word += lastName ? lastName[0] : '';
  return word;
};

export const replaceRectWithText = ({ usersNodes, activeObject, canvas, text }, cb) => {
  replaceSamePlaceholderValueSameType(canvas, (activeObject) => {
    const selectedObject = usersNodes?.find((item) => item?.id === activeObject?.id);
    console.log(selectedObject, activeObject, selectedObject?.type, activeObject?.type, usersNodes, 'vkdebug');

    if (selectedObject && activeObject?.type === 'group') {
      // Get the properties of the rectangle
      let rectProps = {
        left: activeObject.left,
        top: activeObject.top,
        width: selectedObject.width,
        height: selectedObject.height,
        angle: activeObject.angle,
        fill: activeObject.fill,
        stroke: activeObject.stroke,
        strokeWidth: activeObject.strokeWidth
      };

      // Remove the rectangle from the canvas
      canvas.remove(activeObject);

      // Create a new text object with the same properties
      let textbox = new fabric.Textbox(text, {
        left: rectProps.left,
        top: rectProps.top,
        width: rectProps.width,
        height: rectProps.height,
        angle: rectProps.angle,
        fill: rectProps.fill,
        stroke: rectProps.stroke,
        strokeWidth: rectProps.strokeWidth,
        fontSize: rectProps.height / 2, // Initial font size (adjust as needed)
        textAlign: 'center',
        originX: 'left',
        originY: 'top',
        evented: true, // Prevent events like mouse hover
        hasControls: false, // Hide resizing controls
        hasBorders: true, // Hide borders
        lockMovementX: true, // Lock movement along the X-axis
        lockMovementY: true, // Lock movement along the Y-axis
        lockRotation: true, // Lock rotation
        lockScalingX: true, // Lock scaling along the X-axis
        lockScalingY: true, // Lock scaling along the Y-axis,
        actionType: PlaceHolderTypes.valuePlaced,
        id: selectedObject?.id,
        email: selectedObject?.email,
        fontWeight: 'bold',
        fontFamily: 'Times New Roman',
        textAlign: 'left',
        opacity: placeholderReplaceOpacity
      });

      // Function to adjust font size
      const adjustFontSize = (textObject) => {
        let desiredHeight = rectProps.height;
        let desiredWidth = rectProps.width;

        // Reduce font size until the text fits within the desired height and width
        while (textObject.getScaledHeight() > desiredHeight || textObject.getScaledWidth() > desiredWidth) {
          textObject.set({ fontSize: textObject.fontSize - 1 });
        }
      };

      // Adjust the font size of the text object
      adjustFontSize(textbox);

      // Add the new textbox object to the canvas
      canvas.add(textbox);

      // Set the new textbox object as active
      canvas.setActiveObject(textbox);

      // Render the canvas
      canvas.renderAll();
      cb(textbox);
      return textbox;
    }
  });
};

// Function to convert Fabric.js object to image
export const convertFabricTextboxToImage = (fabricObject) => {
  // Get data URL of the object
  let dataURL = fabricObject.toDataURL({
    format: 'png', // or 'jpeg', 'webp', etc.
    quality: 1 // 0 to 1, only applicable for jpeg and webp formats
  });

  // Create a new Image element
  let img = new Image();

  // Set the src attribute with the data URL
  img.src = dataURL;

  // Example: Append the image to the document body
  document.body.appendChild(img);

  // Return the image object if needed
  return img;
};

export const divideTextBySpace = (text) => {
  if (text) {
    let splitData = text.split(' ');
    return splitData;
  } else {
    return [];
  }
};

export const addPlaceHolderAgainUsingExitingValue = (canvas, nodeData, currentLoggedInUserEmail) => {
  console.log(canvas, nodeData, currentLoggedInUserEmail, 'currentLoggedInUserEmail');
  if (nodeData) {
    const scaleX = nodeData.scaleX;
    const scaleY = nodeData.scaleY;
    const rect = new fabric.Rect({
      left: nodeData.x,
      top: nodeData.y,
      // width: nodeData?.width * scaleX,
      // height: nodeData?.height * scaleY,
      width: nodeData?.width,
      height: nodeData?.height,
      fill: nodeData?.color,
      selectable: false,
      evented: false,
      // rx: 20,
      // ry: 20,
      stroke: '#D1D9E0',
      strokeWidth: 2
    });

    // Add text to the rectangle
    const text = new fabric.Text(nodeData.name, {
      fontSize: 11 * scaleX,
      fill: 'black',
      selectable: false, // Text should not be selectable independently
      evented: false,
      fontFamily: 'Roboto',
      fontWeight: 400
    });

    // Center the text within the rectangle
    const textLeft = rect.left + (rect.width - text.width) / 2;
    const textTop = rect.top + (rect.height - text.height) / 2;
    text.set({
      left: textLeft,
      top: textTop
    });

    const group = new fabric.Group([rect, text], {
      selectable: true, // Allow selection of the group
      id: nodeData.id,
      left: nodeData.x,
      top: nodeData.y,
      // width: nodeData?.width * scaleX,
      // height: nodeData?.height * scaleY,
      width: nodeData?.width,
      height: nodeData?.height,
      actionType: nodeData?.type,
      email: currentLoggedInUserEmail
    });

    canvas.add(group);

    // Enable drag and drop for the group
    group.setControlsVisibility({
      mt: false, // top-middle
      mb: false, // bottom-middle
      ml: false, // middle-left
      mr: false, // middle-right
      mtr: false
    });

    // canvas.bringToFront(group);
    // console.log(group, group?.bringToFront, 'groupData');
    // canvas.renderAll();
    return group;
  } else {
    console.error('dropItem should not be empty');
  }
};

export function isValidEmail(email) {
  // Regular expression pattern for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

// export const replaceStylizeSignature = ({ usersNodes, activeObject, canvas, text, textBoxProps = {}, fontSize }) => {
//   const selectedObject = usersNodes?.find((item) => item?.id === activeObject?.id);
//   console.log(selectedObject, activeObject, selectedObject?.type, activeObject?.type, 'vkdebug');

//   if (selectedObject && activeObject?.type === 'group') {
//     // Get the properties of the rectangle
//     let rectProps = {
//       left: activeObject.left,
//       top: activeObject.top,
//       width: selectedObject.width,
//       height: selectedObject.height,
//       angle: activeObject.angle,
//       fill: activeObject.fill,
//       stroke: activeObject.stroke,
//       strokeWidth: activeObject.strokeWidth
//     };

//     // Remove the rectangle from the canvas
//     canvas.remove(activeObject);

//     // Create a new text object with the same properties
//     let textbox = new fabric.Textbox(text, {
//       left: rectProps.left,
//       top: rectProps.top,
//       width: rectProps.width,
//       height: rectProps.height,
//       angle: rectProps.angle,
//       fill: rectProps.fill,
//       stroke: rectProps.stroke,
//       strokeWidth: rectProps.strokeWidth,
//       fontSize: rectProps.height / 2, // Initial font size (adjust as needed)
//       textAlign: 'center',
//       originX: 'left',
//       originY: 'top',
//       evented: true, // Prevent events like mouse hover
//       hasControls: false, // Hide resizing controls
//       hasBorders: true, // Hide borders
//       lockMovementX: true, // Lock movement along the X-axis
//       lockMovementY: true, // Lock movement along the Y-axis
//       lockRotation: true, // Lock rotation
//       lockScalingX: true, // Lock scaling along the X-axis
//       lockScalingY: true, // Lock scaling along the Y-axis,
//       actionType: PlaceHolderTypes.valuePlaced,
//       id: selectedObject?.id,
//       // fontWeight: 'bold',
//       fontFamily: 'Times New Roman',
//       textAlign: 'left',
//       // fontSize: fontSize === FitIntoBoxStyleSignConst ? rectProps.height / 2 : fontSize,
//       ...textBoxProps
//     });

//     // Function to adjust font size
//     const adjustFontSize = (textObject) => {
//       let desiredHeight = rectProps.height;
//       let desiredWidth = rectProps.width;

//       // Reduce font size until the text fits within the desired height and width
//       while (textObject.getScaledHeight() > desiredHeight || textObject.getScaledWidth() > desiredWidth) {
//         textObject.set({ fontSize: textObject.fontSize - 1 });
//       }
//     };
//     textbox.set({
//       top: rectProps.top + (rectProps.height - textbox.getScaledHeight()) / 2
//     });

//     // Adjust the font size of the text object
//     adjustFontSize(textbox);
//     console.log(textbox.getScaledHeight(), rectProps.height, textbox.fontSize, 'vkdebug28');
//     // Add the new textbox object to the canvas
//     canvas.add(textbox);

//     // Set the new textbox object as active
//     canvas.setActiveObject(textbox);

//     // Render the canvas
//     canvas.renderAll();
//     return textbox;
//   }
// };

export const replaceStylizeSignature = ({ usersNodes, activeObject, canvas, text, textBoxProps = {}, fontSize, isAddOnCanvas = true }) => {
  const selectedObject = usersNodes?.find((item) => item?.id === activeObject?.id);
  console.log(selectedObject, activeObject, selectedObject?.type, activeObject?.type, 'vkdebug');

  if (selectedObject && activeObject?.type === 'group') {
    // Get the properties of the rectangle
    let rectProps = {
      left: activeObject.left,
      top: activeObject.top,
      width: selectedObject.width,
      height: selectedObject.height,
      angle: activeObject.angle,
      fill: activeObject.fill,
      stroke: activeObject.stroke,
      strokeWidth: activeObject.strokeWidth
    };

    // Remove the rectangle from the canvas
    if (isAddOnCanvas) {
      canvas.remove(activeObject);
    }

    // Create a new fabric.Text object for single-line text
    let textObject = new fabric.Text(text, {
      left: rectProps.left,
      top: rectProps.top,
      fontSize: fontSize || rectProps.height / 2, // Set an initial font size based on the rectangle height
      fill: rectProps.fill,
      stroke: rectProps.stroke,
      strokeWidth: rectProps.strokeWidth,
      textAlign: 'center', // Center align text
      originX: 'left',
      originY: 'top',
      fontFamily: 'Times New Roman',

      evented: true, // Disable interaction events
      selectable: true, // Make the text object unselectable
      hoverCursor: 'default', // No special cursor on hover
      hasControls: false, // Hide resizing controls
      hasBorders: true, // Hide borders around the object
      lockMovementX: true, // Lock movement on X-axis
      lockMovementY: true, // Lock movement on Y-axis
      lockScalingX: true, // Lock scaling on X-axis
      lockScalingY: true, // Lock scaling on Y-axis
      lockRotation: true, // Lock rotation
      editable: false, // Disable text editing
      actionType: PlaceHolderTypes.valuePlaced, // Retain your custom properties
      id: selectedObject?.id, // Custom ID for the text object
      ...textBoxProps
    });

    // Function to scale the text horizontally to fill the rectangle width in one line
    const scaleTextToFitWidthInOneLine = (textObject) => {
      let desiredWidth = rectProps.width;

      // Keep reducing font size until text fits in the desired width
      while (textObject.width > desiredWidth) {
        textObject.set({ fontSize: textObject.fontSize - 1 });
        textObject.set({ scaleX: 1 });
        textObject.set({ scaleY: 1 });
        canvas.renderAll();
      }

      // Center the text vertically within the rectangle
      textObject.set({
        top: rectProps.top + (rectProps.height - textObject.getScaledHeight()) / 2,
        left: rectProps.left
      });
    };

    // Scale the text to fit the full width in one line
    scaleTextToFitWidthInOneLine(textObject);
    if (isAddOnCanvas) {
      console.log(textObject.getScaledHeight(), rectProps.height, textObject.fontSize, 'vkdebug28');

      // Add the new text object to the canvas
      canvas.add(textObject);

      // Ensure the text object is not selectable or editable
      textObject.set({
        evented: true, // Disable any interaction with the object
        selectable: true, // Prevent user from selecting it
        editable: false // Disable editing of the text
      });

      // Set the new text object as active (optional, you can skip this if not needed)
      canvas.setActiveObject(textObject);

      // Render the canvas
      canvas.renderAll();
    }

    return textObject;
  }
};

export const RecipientDropdownActionsConst = {
  NeedsToSign: 'sign',
  NeedsToApprove: 'approve',
  DocumentSent: 'send',
  Decline: 'decline',
  invalid: 'invalid',
  void: 'voided'
};

export const getRecipientOnlySign = (recipientList) => {
  const receipientListOnlySignUsers = recipientList.filter((item) => item?.actionType === RecipientDropdownActionsConst.NeedsToSign);
  return receipientListOnlySignUsers;
};

export const InboxStatusActions = {
  signByYou: { title: 'Signed by you', Icon: SignedByYouIcon, textColor: '#2E844A' },
  approvedByYou: { title: 'Approved by you', Icon: ApprovedIcon, textColor: '#3B82F6' },
  declinedByYou: { title: 'Declined by you', Icon: () => {}, textColor: '#101010' }
};

// export function formatDateTime(timestamp) {
//   const date = new Date(timestamp);

//   // Get month names array
//   const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

//   // Extract date components
//   const month = months[date.getMonth()];
//   const day = date.getDate();

//   // Extract time components
//   let hours = date.getHours();
//   const minutes = String(date.getMinutes()).padStart(2, '0');
//   const ampm = hours >= 12 ? 'pm' : 'am';

//   hours = hours % 12;
//   hours = hours ? hours : 12; // If hour is 0, display as 12

//   // return `${month} ${day} ${hours}:${minutes} ${ampm}`;
//   return { date: `${month} ${day}`, time: `${hours}:${minutes} ${ampm}`, withYear: `${day} ${month} ${date.getFullYear()}` };
// }
export const toIsoStringOneFormat = (timestamp) => {
  const isoDate = new Date(timestamp);

  const utcYear = isoDate.getUTCFullYear();
  const utcMonth = isoDate.getUTCMonth(); // Remember months are 0-indexed (0 = January, 11 = December)
  const utcDate = isoDate.getUTCDate();
  const utcHours = isoDate.getUTCHours();
  const utcMinutes = isoDate.getUTCMinutes();
  const utcSeconds = isoDate.getUTCSeconds();
  const utcMilliseconds = isoDate.getUTCMilliseconds();
  const utcDateObj = new Date(utcYear, utcMonth, utcDate, utcHours, utcMinutes, utcSeconds, utcMilliseconds);
  console.log(utcDateObj, 'utcDateObj');
  return utcDateObj;
  // const myLocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // const timeZone = localStorage.getItem('timezone') || myLocalTimezone; // Default to UTC
  // const dateInTimezone = moment.utc(timestamp).tz(timeZone).toDate();

  // console.log(myLocalTimezone, timeZone);

  // return dateInTimezone;
};

export const convertToTimezone = (timestamp) => {
  const timeZone = localStorage.getItem('timezone') || defaultTimezone; // Default to UTC if not set
  const parsedTimestamp = moment.utc(timestamp).tz(timeZone);
  const parsedTimezone = moment().tz(timeZone);

  return { parsedTimestamp, parsedTimezone }; // Return Moment object (not JavaScript Date)
};

export const currentTimeInUtc = (timestamp) => {
  const timeZone = 'UTC'; // Default to UTC if not set
  const parsedTimestamp = moment.utc(timestamp).tz(timeZone).format('h:mm a z');
  const parsedTimezone = moment().tz(timeZone);

  return ` (${parsedTimestamp})`; // Return Moment object (not JavaScript Date)
};

export const formatDateTime = (timestamp) => {
  const { parsedTimestamp, parsedTimezone } = convertToTimezone(timestamp); // Convert to the correct time zone
  const now = parsedTimezone.valueOf();
  console.log(now, parsedTimezone, 'nowData');
  // Calculate time difference in milliseconds
  const diffInMillis = now - parsedTimestamp;

  // Calculate time elapsed in hours and minutes
  const diffInMinutes = Math.floor(diffInMillis / 60000);
  const diffInHours = Math.floor(diffInMillis / 3600000);

  // Use toObject() to get date components
  const dateObj = parsedTimestamp.toObject(); // Get the components (year, month, day, etc.)
  console.log(dateObj, 'dateObj');
  // Format the components to create the desired format
  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][dateObj.months];
  const day = dateObj.date;
  const year = dateObj.years;

  // Use toObject to get time components
  let hours = dateObj.hours;
  const minutes = String(dateObj.minutes).padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';

  hours = hours % 12;
  hours = hours ? hours : 12; // If hour is 0, display as 12

  // Determine notification time
  let notificationTime;
  if (diffInMinutes < 60) {
    notificationTime = `${diffInMinutes} min${diffInMinutes !== 1 ? 's' : ''} ago`;
  } else if (diffInHours < 24) {
    notificationTime = `${diffInHours} hr${diffInHours !== 1 ? 's' : ''} ago`;
  } else {
    notificationTime = `${day} ${month} ${year}`; // If more than 24 hours, show full date
  }

  return {
    date: `${month} ${day}`,
    time: `${hours}:${minutes} ${ampm}`,
    withYear: `${day} ${month} ${year}`,
    notificationTime: notificationTime,
    withYearWithSlash: `${day}/${month}/${year}`
  };
};

// export function formatDateTime(timestamp) {
//   const date = convertToTimezone(timestamp);
//   const now = new Date();

//   // Get month names array
//   const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

//   // Extract date components
//   const month = months[date.getMonth()];
//   const day = date.getDate();
//   const year = date.getFullYear();

//   // Extract time components
//   let hours = date.getHours();
//   const minutes = String(date.getMinutes()).padStart(2, '0');
//   const ampm = hours >= 12 ? 'pm' : 'am';

//   hours = hours % 12;
//   hours = hours ? hours : 12; // If hour is 0, display as 12

//   // Calculate time difference in milliseconds
//   const diffInMillis = now - date;

//   // Calculate time elapsed in hours and minutes
//   const diffInMinutes = Math.floor(diffInMillis / 60000);
//   const diffInHours = Math.floor(diffInMillis / 3600000);

//   // Determine notification time
//   let notificationTime;
//   if (diffInMinutes < 60) {
//     notificationTime = `${diffInMinutes} min${diffInMinutes !== 1 ? 's' : ''} ago`;
//   } else if (diffInHours < 24) {
//     notificationTime = `${diffInHours} hr${diffInHours !== 1 ? 's' : ''} ago`;
//   } else {
//     notificationTime = `${day} ${month} ${year}`; // If more than 24 hours, show full date
//   }

//   return {
//     date: `${month} ${day}`,
//     time: `${hours}:${minutes} ${ampm}`,
//     withYear: `${day} ${month} ${year}`,
//     notificationTime: notificationTime,
//     withYearWithSlash: `${day}/${month}/${year}`
//   };
// }

export const getUserFullNameInitials = (userFullName) => {
  const firstName = divideTextBySpace(userFullName)?.[0];
  const lastName = divideTextBySpace(userFullName)?.[1];
  return `${firstName?.[0] || ''}${lastName?.[0] || ''}`;
};

export function formatDateDMY(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export function formateDateWithDash(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
  const day = String(date.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function calculateCompletedWorkPercentage(totalPeople, pendingPeople) {
  if (totalPeople === 0) {
    return 0; // To avoid division by zero
  }

  const completedPeople = totalPeople - pendingPeople;
  const completedPercentage = (completedPeople / totalPeople) * 100;

  return { signedCount: completedPeople, percent: completedPercentage };
}

export const DocumentListApiType = {
  inbox: 'inbox',
  completed: 'completed',
  sent: 'sent',
  archive: 'archive',
  trash: 'trash',
  myPendingSignatures: 'my_pending_sign',
  myAwaitingSignatures: 'awaiting_sign',
  myExpiringShortlyDoc: 'expiring_shortly',
  myExpiredDoc: 'expired'
};

const documentStatusConst = ['in_progress', 'executed', 'declined', 'voided', 'expired'];

export function esignDateNTimeNewFormat() {
  const now = new Date();

  // Extract components
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(now.getDate()).padStart(2, '0');
  const year = String(now.getFullYear()).slice(-2); // Get last 2 digits of the year
  let hour = now.getHours();
  const minute = String(now.getMinutes()).padStart(2, '0');

  // Determine AM/PM and format hour
  const ampm = hour >= 12 ? 'pm' : 'am';
  hour = hour % 12;
  hour = hour ? String(hour).padStart(2, '0') : '12'; // The hour '0' should be '12'

  // Format the final string
  return `${month}/${day}/${year} at ${hour}:${minute}${ampm}`;
}

export const replaceSignPlaceholderValue = ({ img, canvas }, cb) => {
  const activeObj = canvas?.getActiveObjects();
  const selectedObjects = canvas.getActiveObjects();

  if (selectedObjects.length === 0) {
    toast.error('Something went wrong!');
    return;
  }
  // Get properties of the selected object
  const { left, top, width, height, id } = selectedObjects[0];
  const customRect = createCustomRect({
    left: left,
    top: top,
    width: width,
    height: height,
    fill: 'transparent',
    stroke: 'black',
    strokeWidth: 2,
    topText: `Esigned on ${esignDateNTimeNewFormat()}`,
    leftText: ' DIGI-EBOOKS   ',
    bottomText: ' 1b005fb7-bbb0-44b2-940f-36c01d0a2cb7',
    borderRadius: 4,
    svgImage: img
  });
  canvas.add(customRect);
  canvas.remove(...selectedObjects);
  cb(customRect);
  canvas.renderAll();
};

export const uploadSignHelper = (event, cb) => {
  event.stopPropagation();
  const file = event.target.files[0];

  if (file) {
    const fileSizeInKB = file.size / 1024; // Convert bytes to kilobytes (KB)

    // Check if file size is within the required range
    if (fileSizeInKB < 10) {
      toast.error('File size is too small. Please upload an image larger than 10KB.');
      return;
    } else if (fileSizeInKB > 2048) {
      // 2MB is 2048KB
      toast.error('File size is too large. Please upload an image smaller than 2MB.');
      return;
    }

    const img = new Image();
    img.onload = () => {
      const width = img.width;
      const height = img.height;

      // Check dimensions
      if (width < 300 || height < 100) {
        toast.error('Invalid image dimensions. Please upload an image with minmum 300px width and 100px height.');
        return;
      }
      cb(reader.result);
    };

    const reader = new FileReader();
    reader.onload = (e) => {
      img.src = e.target.result; // Set image source to trigger onload
    };
    reader.readAsDataURL(file);
  }
};

export const clearAllLocalStorage = () => {
  // List of keys to keep
  const keysToKeep = ['placeholderTourSeen', 'timezone'];

  // Create an object to hold the values of the keys to keep
  const valuesToKeep = {};

  // Get the values of the keys you want to keep
  keysToKeep.forEach((key) => {
    const value = localStorage.getItem(key);
    if (value !== null) {
      valuesToKeep[key] = value;
    }
  });

  // Clear all localStorage
  localStorage.clear();

  // Re-add the items you want to keep
  Object.keys(valuesToKeep).forEach((key) => {
    localStorage.setItem(key, valuesToKeep[key]);
  });
};

export const signUpOrgTypeConst = {
  organization: 'organisation',
  individual: 'individual'
};

export function getValidityTimeFormat(timestamp = '') {
  if (!timestamp) {
    return '-';
  }
  // Convert the timestamp into a Date object
  const [datePart, timePart, period] = timestamp.split(' ');
  const [year, month, day] = datePart.split('-');
  const [hours, minutes] = timePart.split(':');

  // Adjust hours based on AM/PM
  let hour = parseInt(hours);
  if (period === 'PM' && hour < 12) hour += 12;
  if (period === 'AM' && hour === 12) hour = 0;

  // Create the date object
  const date = new Date(year, month - 1, day, hour, minutes);

  // Format the date
  const formattedHour = date.getHours() % 12 || 12;
  const formattedMinutes = date.getMinutes().toString().padStart(2, '0');
  const formattedPeriod = date.getHours() >= 12 ? 'pm' : 'am';
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return `Till ${formattedHour}:${formattedMinutes} ${formattedPeriod}, ${monthNames[date.getMonth()]} ${date.getDate()}`;
}

export const sameDurationForEveryoneId = 'sameDurationForEveryone';

export function getSearchFilterDateWithType(type) {
  const today = new Date();
  const startOfDay = new Date(today.setHours(0, 0, 0, 0)); // Start of today in local time

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getLastWeekRange = () => {
    const todayDayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    const lastSunday = new Date(startOfDay);
    const lastMonday = new Date(startOfDay);

    // Calculate last week's Monday
    lastMonday.setDate(today.getDate() - todayDayOfWeek - 6);
    // Calculate last week's Sunday
    lastSunday.setDate(today.getDate() - todayDayOfWeek);

    return { startDate: formatDate(lastMonday), endDate: formatDate(lastSunday) };
  };

  const getLastMonthRange = () => {
    const lastMonthDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthStart = new Date(lastMonthDate.getFullYear(), lastMonthDate.getMonth(), 1); // 1st day of last month
    const lastMonthEnd = new Date(lastMonthDate.getFullYear(), lastMonthDate.getMonth() + 1, 0); // Last day of last month

    return { startDate: formatDate(lastMonthStart), endDate: formatDate(lastMonthEnd) };
  };

  const getLastNMonthsRange = (n) => {
    const startDate = new Date(today.getFullYear(), today.getMonth() - n, 1); // 1st day of N months ago
    const endDate = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month

    return { startDate: formatDate(startDate), endDate: formatDate(endDate) };
  };

  let startDate, endDate;

  switch (type) {
    case 'all':
      startDate = null;
      endDate = null;
      break;
    case 'today':
      startDate = formatDate(startOfDay);
      const newDate = new Date(startOfDay);
      newDate.setDate(startOfDay.getDate() + 1);

      endDate = formatDate(newDate);
      break;
    case 'yesterday':
      const yesterdayStartDate = new Date(startOfDay);
      yesterdayStartDate.setDate(yesterdayStartDate.getDate() - 1);
      const yesterdayEndDate = new Date(startOfDay);

      startDate = formatDate(yesterdayStartDate);
      endDate = formatDate(yesterdayEndDate);
      break;
    case 'last_week':
      ({ startDate, endDate } = getLastWeekRange());
      break;
    case 'last_month':
      ({ startDate, endDate } = getLastMonthRange());
      break;
    case 'last_3_months':
      ({ startDate, endDate } = getLastNMonthsRange(3));
      break;
    case 'last_6_months':
      ({ startDate, endDate } = getLastNMonthsRange(6));
      break;
    case 'selectDate':
      startDate = null;
      endDate = null;
      break;
    default:
      throw new Error('Invalid type provided');
  }

  console.log(type, { startDate, endDate }, 'debug908');
  return { startDate, endDate };
}

export const homeLandingPageRotateTextArray = [
  'Contracts',
  'Notices',
  'Litigation',
  'Regulatory',
  'Summons',
  'Petitions',
  'Compliance',
  'Banking',
  'Legal',
  'Finance',
  'Employment',
  'Secretarial',
  'Procurement',
  'Sales',
  'Marketing',
  'Human Resources'
];

// export function cn(...inputs) {
//   return twMerge(clsx(inputs));
// }

export const createDocumentType = {
  createDocumentType: 'createDocumentType',
  IamTheOnlySigner: 'IamTheOnlySigner',
  MultiPartySigner: 'MultiPartySigner'
};
