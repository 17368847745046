import BackwardIcon from 'app/assets/icons/BackwardIcon';
import ForwardIcon from 'app/assets/icons/ForwardIcon';
import useCanvasNavigatePlaceholders from 'hooks/useCanvasNavigatePlaceholders';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { PlaceHolderTypes } from 'utils/esign.helper';

const SignMarker = () => {
  // const [signMarkerTop, setSignMarkerTop] = useState(0);
  // const canvas = useSelector(selectReplaceCanvasAction);
  // const [sortedPlaceholderList, setSortedPlaceholderList] = useState(null);
  // const [currentSignMarkIndex, setCurrentSignMarkIndex] = useState(0);

  // const totalPlaceholderLength = useMemo(() => Object.keys(sortedPlaceholderList || {}).length, [sortedPlaceholderList]);
  // const currentPlaceholder = sortedPlaceholderList?.[currentSignMarkIndex];

  // const onSignMarkerNext = useCallback(
  //   (topIndex) => {
  //     if (topIndex < 0 || topIndex >= totalPlaceholderLength) return;

  //     const canvasParentContainer = document.getElementById('esign_document_canvas_container');
  //     const topValue = sortedPlaceholderList[topIndex]?.top;
  //     console.log(topValue, 'topValue');
  //     if (topValue !== undefined) {
  //       setSignMarkerTop(topValue);
  //       canvasParentContainer.scroll({
  //         top: topValue,
  //         behavior: 'smooth'
  //       });
  //       setCurrentSignMarkIndex(topIndex);
  //     }
  //   },
  //   [sortedPlaceholderList, totalPlaceholderLength]
  // );

  // useEffect(() => {
  //   if (canvas !== null) {
  //     const onAfterRender = () => {
  //       if (!sortedPlaceholderList) {
  //         const placeholders = canvas?.getObjects()?.filter((item) => Object.values(PlaceHolderTypes).includes(item?.actionType));

  //         if (placeholders?.length) {
  //           const sortedData = placeholders.sort((a, b) => a.top - b.top);
  //           const placeholderList = sortedData.map((item, index) => ({
  //             top: item?.top,
  //             count: index + 1
  //           }));
  //           setSortedPlaceholderList(placeholderList);
  //         }
  //       }
  //     };

  //     canvas.on('after:render', onAfterRender);

  //     // Cleanup event listener when component unmounts
  //     return () => {
  //       canvas.off('after:render', onAfterRender);
  //     };
  //   }
  // }, [canvas, sortedPlaceholderList]);
  // console.log(sortedPlaceholderList, canvas?.getObjects(), 'uploadedDocumentAllDatadebugfinal');
  const { goNext, goPrev, signMarkerTop, goToSignMarker, totalPlaceholderLength, currentPlaceholder } = useCanvasNavigatePlaceholders();
  return (
    <div className="relative z-50 w-full">
      {totalPlaceholderLength > 0 && (
        <>
          {signMarkerTop === 0 ? (
            <button
              className="absolute left-0 z-auto border-[1px] border-[#16A34A] font-[500] text-[16px] text-[#16A34A] rounded-tl-[6px] rounded-bl-[6px] px-3 py-1"
              onClick={() => goToSignMarker(0)}
              style={{ top: `${signMarkerTop}px` }}
            >
              Start
            </button>
          ) : (
            <div
              className="absolute left-0 z-auto border-[1px] border-[#16A34A] h-[66px] w-[68px] flex flex-col items-center justify-center rounded-[6px]"
              style={{ top: `${signMarkerTop}px` }}
            >
              <div className="font-[500] text-[16px] text-[#16A34A]">{`${currentPlaceholder?.count || 0}/${totalPlaceholderLength}`}</div>
              <div className="flex gap-[20px] mt-[8px]">
                <button
                  onClick={goPrev}
                  className="disabled:opacity-50"
                  //   disabled={currentSignMarkIndex <= 0}
                >
                  <BackwardIcon />
                </button>
                <button
                  onClick={goNext}
                  className="disabled:opacity-50"
                  //   disabled={currentSignMarkIndex >= totalPlaceholderLength - 1}
                >
                  <ForwardIcon />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SignMarker;
