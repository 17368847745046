import React, { createContext, useContext, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { updateUserPreferences } from 'services/eSignService';
import { toast } from 'react-toastify';
export const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// Create context
const TimezoneContext = createContext();
export const updateTimezoneInLocalstorage = (timezone) => {
  localStorage.setItem('timezone', timezone) || defaultTimezone;
};
// Provider component
export const TimezoneProvider = ({ children }) => {
  const [timezone, setTimezone] = useState(localStorage.getItem('timezone') || defaultTimezone);
  const updateInApi = async (timezone) => {
    try {
      const data = await updateUserPreferences({ time_zone: timezone });
      if (data?.message) {
        toast.success('User preferences updated successfully!');
      }
      console.log(data, 'dataOpData');
    } catch (error) {
      toast.success('User preferences not updated. try again later!');
    }
  };
  const changeTimezone = (selectedTimezone) => {
    console.log(selectedTimezone, 'selectedTimezone');
    setTimezone(selectedTimezone);
    localStorage.setItem('timezone', selectedTimezone);
    updateInApi(selectedTimezone);
  };

  return <TimezoneContext.Provider value={{ timezone, changeTimezone }}>{children}</TimezoneContext.Provider>;
};

// Custom hook to use timezone
export const useTimezone = () => {
  return useContext(TimezoneContext);
};

// export const  getDateNTimeFormatted = ()=>{

// }
