import CrossIcon from 'app/assets/icons/CrossIcon';
import DeclineIcon from 'app/assets/icons/DeclineIcon';
import { Button, Checkbox, Label, Modal, Textarea, TextInput } from 'flowbite-react';
import { useState } from 'react';
import EsignAppPrimaryButton from '../Buttons/EsignAppPrimaryButton';
import EsignAppSecondaryButton from '../Buttons/EsignAppSecondaryButton';
import { updateDocumentStatus } from 'services/eSignService';
import { useDispatch } from 'react-redux';
import { setEsignMainAppLoader } from 'store/esignMainSlice';
import { useAccount } from 'context/AccountProvider';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { RecipientDropdownActionsConst } from 'utils/esign.helper';
import MarkAsInvalidIcon from 'app/assets/icons/MarkAsInvalidIcon';

export function MarkAsInvalidDocumentPopup({ open, onClose, documentId, isFromCanvas }) {
  const dispatch = useDispatch();
  const { userDetails } = useAccount();
  const naviagte = useNavigate();
  const [reasonText, setReasonText] = useState('');
  const handleDecline = async () => {
    try {
      dispatch(setEsignMainAppLoader(true));
      console.log(userDetails?.email, 'userDetails?.email');
      const data = await updateDocumentStatus({
        documentId,
        userEmail: userDetails?.email,
        status: RecipientDropdownActionsConst.void,
        payload: { reason: reasonText }
      });
      if (data?.message) {
        toast.success(data?.message);
        onClose();
        window.location.href = '/dashboard/home';
      }
    } catch (error) {
      console.log(error, 'erorrData');
      toast.error(error.message);
    } finally {
      dispatch(setEsignMainAppLoader(false));
    }
  };
  console.log(userDetails, 'userDetails123');
  return (
    <>
      <Modal show={open} size="md" onClose={onClose} popup theme={theme}>
        {/* <Modal.Header /> */}
        <Modal.Body>
          <div className="p-[20px]">
            <div className="w-full flex items-start justify-between">
              <div className="flex items-start gap-[12px]">
                <div className="w-[40px] h-[36px] bg-[#FEDED8] rounded-full flex items-center justify-center">
                  <MarkAsInvalidIcon />
                </div>
                <div className="w-full">
                  <div>
                    <h3 className="text-[16px] font-[700] text-[#101010]">Mark as void</h3>
                    <p className="text-[14px] font-[500] text-[#6B7280] pt-[8px]">
                      Are you sure you want to mark this document as void? Marking it as void will make it inaccessible for everyone and
                      permanently end its validity.
                    </p>
                  </div>
                </div>
              </div>
              <button onClick={onClose}>
                <CrossIcon />
              </button>
            </div>
            <div className="w-full flex items-end justify-end flex-col ">
              <div className="mt-[20px] flex items-center gap-[10px]">
                {<EsignAppSecondaryButton title={'Mark As Void'} onClick={handleDecline} />}
                {!isFromCanvas ? <EsignAppPrimaryButton title={'Cancel'} onClick={onClose} /> : null}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const theme = {
  root: {
    base: 'fixed inset-x-0 top-0 z-50 h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full',
    show: {
      on: 'flex bg-[black] bg-opacity-50',
      off: 'hidden'
    },
    sizes: {
      sm: 'max-w-sm',
      md: 'max-w-md',
      lg: 'max-w-lg',
      xl: 'max-w-xl',
      '2xl': 'max-w-2xl',
      '3xl': 'max-w-3xl',
      '4xl': 'max-w-4xl',
      '5xl': 'max-w-5xl',
      '6xl': 'max-w-6xl',
      '7xl': 'max-w-7xl'
    },
    positions: {
      'top-left': 'items-start justify-start',
      'top-center': 'items-start justify-center',
      'top-right': 'items-start justify-end',
      'center-left': 'items-center justify-start',
      center: 'items-center justify-center',
      'center-right': 'items-center justify-end',
      'bottom-right': 'items-end justify-end',
      'bottom-center': 'items-end justify-center',
      'bottom-left': 'items-end justify-start'
    }
  },
  content: {
    base: 'relative h-full w-full p-4 md:h-auto',
    inner: 'relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700 w-[520px]'
  },
  body: {
    base: 'flex-1 overflow-auto',
    popup: 'pt-0'
  },
  header: {
    base: 'flex items-start justify-between rounded-t border-b p-5 dark:border-gray-600',
    popup: 'border-b-0 p-2',
    title: 'text-xl font-medium text-gray-900 dark:text-white',
    close: {
      base: 'ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white',
      icon: 'h-5 w-5'
    }
  },
  footer: {
    base: 'flex items-center space-x-2 rounded-b border-gray-200 p-6 dark:border-gray-600',
    popup: 'border-t'
  }
};
