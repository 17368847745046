import React from 'react';

const PrimaryButton = ({ Icon, title, onClick, ...props }) => {
  return (
    <button
      type="button"
      className={`bg-[#216DDE] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 border-b-[4px] border-[#184EA0] w-[140px] flex items-center gap-3 justify-center text-[15px] rounded-xl disabled:bg-[#A0A0A0] disabled:border-[#A0A0A0] `}
      onClick={onClick}
      {...props}
    >
      {Icon && <Icon />}
      {title}
    </button>
  );
};

export default PrimaryButton;
