import * as yup from 'yup';

export const accountSettingValidationSchema = yup.object().shape({
  phone: yup
    .string()
    .required('Phone Number is required')
    .matches(/^\+91\d{10}$/, 'Phone number should be in the format +91XXXXXXXXXX'), // Ensures +91 and 10 digits
  oldPassword: yup.string().optional(),
  newPassword: yup.string().when('oldPassword', {
    is: (val) => !!val, // Validate confirm password if password is provided
    then: yup
      .string()
      .min(8, 'New Password must be at least 8 characters')
      .matches(/[a-z]/, 'New Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'New Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'New Password must contain at least one number')
      .matches(/[\W_]/, 'New Password must contain at least one special character') // \W matches any non-word character, and _ accounts for the underscore
      .required('New Password is required')
  })
});
