import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import AccountProvider from 'context/AccountProvider';
import { ToastContainer } from 'react-toastify';
import ConfirmSendingEsignDoc from 'layout/MainLayoutNewUI/Popups/ConfirmSendingEsignDoc';
import EsignAppMainLoader from 'app/components/MainLoader';
import useLoadTailwindCss from 'hooks/useLoadTailwindCss';
import AuthenticationRoutes, { AuthRoutes } from 'routes/AuthenticationRoutes';
import { Navigate, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import WebsiteOpenLoader from 'app/components/MainLoader/WebsiteOpenLoader';
import { TimezoneProvider } from 'context/TimezoneContext';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const esignMainAppLoader = useSelector((state) => state.esignMain.esignMainAppLoader);
  // window.addEventListener('load', localStorage.clear());
  console.log(esignMainAppLoader, 'esignMainAppLoader');
  const location = useLocation();
  const isScssLoaded = useLoadTailwindCss();
  const [isLoading, setIsLoading] = useState(true);

  const publicRoutes = AuthRoutes.map((item) => item.path);

  // useEffect(() => {
  //   const handleLoad = () => {
  //     setIsLoading(false);
  //   };

  //   window.addEventListener('load', handleLoad);

  //   // Cleanup event listener
  //   return () => {
  //     window.removeEventListener('load', handleLoad);
  //   };
  // }, []);

  if (publicRoutes.includes(location.pathname)) {
    const token = localStorage.getItem('token');
    if (token) {
      return <Navigate to="/dashboard/home" />;
    }
  }
  console.log(isScssLoaded, 'isScssLoaded');
  if (!isScssLoaded?.isLoaded) {
    return <WebsiteOpenLoader />;
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <ToastContainer />
        {esignMainAppLoader ? <EsignAppMainLoader /> : null}

        {/* <ConfirmSendingEsignDoc /> */}
        <NavigationScroll>
          <AccountProvider>
            <TimezoneProvider>
              <Routes />
            </TimezoneProvider>
          </AccountProvider>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default App;
