import React, { useState } from 'react';
import EsignCanvasAddText from './EsignCanvasAddText';
import { CreateYourInitialsModal } from 'app/components/Popups/CreateYourInitialsModal';
import { CreateYourInitialsModalForCanvas } from 'app/components/Popups/CreateYourInitialsModalForCanvas';
import CommonDropdown from 'app/components/Dropdowns/CommonDropdown';
import { PlaceHolderTypes, replacePlaceholderwithImage, replacePlaceholderwithInitials } from 'utils/esign.helper';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { useSelector } from 'react-redux';
import useGetUserPreferences from 'hooks/useGetUserPreferences';
import { useDispatch } from 'react-redux';
import { setPlaceHolderValue } from 'store/mainSlice';

const EsignAddInitials = ({ canvasRdxAction = selectReplaceCanvasAction, ...props }) => {
  const [selectedType, setSelectedType] = useState({});
  const canvas = useSelector(canvasRdxAction);
  const { initials } = useGetUserPreferences();
  const dispatch = useDispatch();
  const handleOnCanvas = (url) => {
    replacePlaceholderwithInitials(url, canvas, (addedObject) => {
      dispatch(
        setPlaceHolderValue({
          value: { id: addedObject?.id, addedValue: addedObject.toDataURL(), actionType: PlaceHolderTypes.initials }
        })
      );
    });
  };
  const handleOnSelect = (e) => {
    setSelectedType(e);
    if (e?.type === 'use_my_initials') {
      handleOnCanvas(initials);
    }
    console.log(e, 'initials type');
  };
  return (
    <>
      <CreateYourInitialsModalForCanvas
        showModal={selectedType?.type === 'create_new' ? true : false}
        afterUploadCb={(url) => {
          handleOnCanvas(url);
        }}
        onClose={() => setSelectedType({})}
      />

      <CommonDropdown
        items={[
          { title: 'Create New Initials', type: 'create_new' },
          {
            title: 'Use My Initials',
            type: 'use_my_initials',
            customImage: () => {
              return <img src={initials} alt="initials" width={20} />;
            }
          }
        ]}
        className={'addSignPanel'}
        buttonLabel={selectedType?.title || 'Select'}
        onSelect={handleOnSelect}
        dropTopTitle="Initial type"
      />

      {/* <EsignCanvasAddText label={'Initials'} {...props} /> */}
    </>
  );
};

export default EsignAddInitials;
