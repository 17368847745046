import React, { useEffect, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'context/AccountProvider';
import { getDocumentById, getDocUsingLink, getSignConfig, updateReadFlag } from 'services/eSignService';
import {
  selectCombinedSignAndUnSignedNode,
  setCombinedSignAndUnSignedNodeRdx,
  setEsignMainAppLoader,
  setEsignUploadedDocumentsImages,
  setUploadedDocumentAllData,
  setUsersNodes
} from 'store/esignMainSlice';
import { setPublicRecipientDetails } from 'store/mainSlice';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { addSignImage, addSignRectBox, combineSignatures, PlaceHolderTypes } from 'utils/esign.helper';
import _ from 'lodash';
import { isDateTimeExpiredSinceNow } from 'app/helper/selectTime.const';

const useRecipientAddSign = () => {
  const { documentId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const combinedSignAndUnSignedNode = useSelector(selectCombinedSignAndUnSignedNode);
  const canvas = useSelector(selectReplaceCanvasAction);
  const { userDetails } = useAccount();
  const navigate = useNavigate();
  const currentUserEmail = userDetails.email;
  const checkIsDocumentExpired = (resData) => {
    const userEmail = resData.email;
    const currentUserDataConfig = resData?.recipient_configs?.filter?.((item) => {
      return item?.email === userEmail;
    });
    if (currentUserDataConfig?.length > 0) {
      const item = currentUserDataConfig?.[0];
      const isExpired = isDateTimeExpiredSinceNow(item?.expiry_date_time);
      if (item?.status) {
      } else if (isExpired) {
        alert('Document already expired. please contact your admin');
        navigate('/dashboard/documents/inbox');
      }
    }
    console.log(currentUserDataConfig, 'resData');
  };
  const getDocumentByIdHandler = async (id) => {
    try {
      dispatch(setEsignMainAppLoader(true));
      const res = await getDocumentById(id);
      console.log(res, 'updatedNodesObj');
      if (!_.isEmpty(res?.data)) {
        const resData = res?.data?.data;
        checkIsDocumentExpired(resData);
        dispatch(setUploadedDocumentAllData(resData));
        const _temp = resData.document_images?.map((j) => {
          return {
            url: `data:image/png;base64,${j}`
          };
        });

        dispatch(setEsignUploadedDocumentsImages(_temp));
        if (currentUserEmail) {
          const signedValue = combineSignatures({ [currentUserEmail]: resData?.sign_configs[currentUserEmail] }, resData?.signs);

          dispatch(setCombinedSignAndUnSignedNodeRdx(signedValue));
        } else {
          const signedValue = combineSignatures(resData?.sign_configs, resData?.signs);

          dispatch(setCombinedSignAndUnSignedNodeRdx(signedValue));
        }

        if (!_.isEmpty(resData?.signs)) {
          let allObjects = Object.values(resData?.signs).flatMap((array) => array);
          if (currentUserEmail) {
            const filter = allObjects?.filter((j) => j.email === currentUserEmail);
          } else {
            console.log(allObjects, 'allObjects');
          }
          // setAddedSign(resData?.signs[userDetails?.email]);
        }

        const updatedNodesObj = { ...resData?.sign_configs };

        for (const email in updatedNodesObj) {
          if (Object.hasOwnProperty.call(updatedNodesObj, email)) {
            const updatedNodes = updatedNodesObj[email].map((node) => {
              const match = resData?.signs[email]?.find((obj) => obj.id === node.id);

              if (match) {
                return {
                  ...node,
                  imgUrl: match.imgUrl
                };
              }
              return node;
            });
            updatedNodesObj[email] = updatedNodes;
          }
        }
      }
    } catch (error) {
      console.log(error.message, 'errorVp');
    } finally {
      dispatch(setEsignMainAppLoader(false));
    }
  };

  const getSignConfigHandler = async (id) => {
    try {
      const res = await getSignConfig(id);
      console.log(res, 'resData');
      if (res.status === 200) {
        if (Object.keys(res.data.data).length) {
          let allObjects = Object.values(res.data.data).flatMap((array) => array);
          if (currentUserEmail) {
            const filter = allObjects?.filter((j) => j.email === currentUserEmail);

            dispatch(setUsersNodes(filter));
          } else {
            dispatch(setUsersNodes(allObjects));
          }

          // dispatch(setNodes(allObjects));
        } else {
          // dispatch(setNodes([]));
        }
      }
    } catch (error) {
      console.log(error.message, 'errorVp');
    }
  };

  const handleAddPlaceholders = useCallback(
    (item) => {
      addSignRectBox(canvas, item, userDetails?.email);
    },
    [canvas, userDetails?.email]
  );
  //**********For Unauth user *********** */
  // useEffect(() => {
  //   if (documentId) {
  //     getDocumentByIdHandler(documentId);
  //   }
  // }, [documentId, getDocumentByIdHandler]);
  //**************************************** */
  useEffect(() => {
    if ((documentId, !_.isEmpty(userDetails))) {
      getSignConfigHandler(documentId);
      getDocumentByIdHandler(documentId);
    }
  }, [documentId, searchParams, userDetails]);

  useEffect(() => {
    if (canvas && Array.isArray(combinedSignAndUnSignedNode)) {
      combinedSignAndUnSignedNode.forEach((item) => {
        if (item?.objectType === 'signs') {
          addSignImage(item, canvas);
        } else if (item?.objectType === 'sign_config' && !item?.is_aadhaar_true) {
          handleAddPlaceholders(item);
        }
      });
      canvas.renderAll();
    }
  }, [canvas, combinedSignAndUnSignedNode, handleAddPlaceholders]);
  const handleUpdateReadDocument = async (documentId) => {
    try {
      const data = await updateReadFlag([documentId], {
        type: 'document'
      });
      console.log(data, 'data');
    } catch (error) {}
  };
  useEffect(() => {
    handleUpdateReadDocument(documentId);
  }, [documentId]);

  return null;
};

export default useRecipientAddSign;
