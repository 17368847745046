import { useAccount } from 'context/AccountProvider';
import React from 'react';
import { useForm } from 'react-hook-form';
const InputWithLabel = ({ label, placeholder, containerClass, ...props }) => {
  return (
    <div className={containerClass}>
      <label className="block font-[600] text-[14px] text-[#030712] mb-[10px]">{label}</label>
      <input
        className="font-[500] text-[#6B7280] text-[14px] border py-[8px] px-[15px] rounded-[6px] w-full"
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};
const AccountSettingForm = ({ handleChange, formData }) => {
  const { userDetails } = useAccount(); // Assuming you fetch user details from here
  const { email, first_name, last_name, phone } = userDetails;

  return (
    <form>
      <div className="grid grid-cols-12 gap-[30px] mt-[30px] w-[50%]">
        <InputWithLabel label="First Name" value={first_name} containerClass="w-full col-span-6" placeholder={first_name} disabled />
        <InputWithLabel label="Last Name" value={last_name} containerClass="w-full col-span-6" placeholder={last_name} disabled />
        <InputWithLabel label="Email Address" value={email} containerClass="col-span-12" placeholder={email} disabled />
        <InputWithLabel
          label="Phone Number"
          containerClass="col-span-12"
          defaultValue={formData?.phone}
          placeholder="Enter Phone Number"
          name="phone"
          onChange={handleChange}
        />
        <div className="w-full col-span-12">
          <InputWithLabel
            label="Update Password"
            placeholder="Enter Current Password"
            name="oldPassword"
            onChange={handleChange}
            defaultValue={formData?.oldPassword}
            type="password"
          />
          <InputWithLabel
            placeholder="Enter New Password"
            name="newPassword"
            onChange={handleChange}
            defaultValue={formData?.newPassword}
            type="password"
          />
        </div>
      </div>
    </form>
  );
};

export default AccountSettingForm;
