import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Esign from 'views/eSign';
import DocusignContainer from 'views/eSign/docusign-container';
import AddSignature from 'views/eSign/add-signature';
import ESignFabric from 'views/eSign_fabric';
import AddSignatureFabric from 'views/eSign_fabric/AddSignatureFabric';
import DigiESign from 'views/digiEsign';
import AddDigiSign from 'views/digiEsign/addDigiSign';
import MainLayoutNewUI from 'layout/MainLayoutNewUI';
import AddSignOnCanvasForAuthUser from 'app/pages/RoutesPages/AddSignOnCanvas/AddSignOnCanvasForAuthUser';
import AddSignOnCanvasForUnAuthUser from 'app/pages/RoutesPages/AddSignOnCanvas/AddSignOnCanvasForUnAuthUser';
import PersonalSettings from 'app/pages/RoutesPages/Profile/PersonalSettings';
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// const SelectPlans = Loadable(lazy(() => import('views/SelectPlans')));

const MyOrganization = Loadable(lazy(() => import('views/MyOrganization')));

//################ page components ################
const DashboardHome = Loadable(lazy(() => import('app/pages/RoutesPages/DashboardHome')));
const DashboardDocuments = Loadable(lazy(() => import('app/pages/RoutesPages/DashboardDocuments')));
const DashboardCreateDocument = Loadable(lazy(() => import('app/pages/RoutesPages/DashboardCreateDocument')));
const DocInboxListLazy = Loadable(lazy(() => import('app/pages/RoutesPages/Documents/DocInboxList')));
const DocSentListLazy = Loadable(lazy(() => import('app/pages/RoutesPages/Documents/DocSentList')));
const DocCompletedListLazy = Loadable(lazy(() => import('app/pages/RoutesPages/Documents/DocCompletedList')));
const DocArchiveListLazy = Loadable(lazy(() => import('app/pages/RoutesPages/Documents/DocArchiveList')));
const DocTrashListLazy = Loadable(lazy(() => import('app/pages/RoutesPages/Documents/DocTrashList')));
const AccountSettingsLazy = Loadable(lazy(() => import('app/pages/RoutesPages/Profile/AccountSettings')));
const NotificationSettingsLazy = Loadable(lazy(() => import('app/pages/RoutesPages/Profile/NotificationSetting')));
const MyPendingSignatures = Loadable(lazy(() => import('app/pages/RoutesPages/Documents/MyPendingSignatures')));
const MyAwaitingSignature = Loadable(lazy(() => import('app/pages/RoutesPages/Documents/MyAwaitingSignatures')));
const MyExpiredDocuments = Loadable(lazy(() => import('app/pages/RoutesPages/Documents/MyExpiredDocument')));
const MyExpiringShortlyDocument = Loadable(lazy(() => import('app/pages/RoutesPages/Documents/MyExpiringShortlyDocument')));
//#######################################################
// ==============================|| MAIN ROUTING ||============================== //
// const token = localStorage.getItem('token');
// const MainRoutes = {
//   path: '/dashboard/',
//   element: <MainLayoutNewUI />,
//   children: [
//     {
//       path: '/dashboard/',
//       element: <DashboardDefault />
//     },
//     {
//       path: '/dashboard/my-organization',
//       element: <MyOrganization />
//     },
//     {
//       path: '/dashboard/my-organization',
//       element: <MyOrganization />
//     },
//     {
//       path: '/dashboard/eSign',
//       element: <Esign />
//     },
//     {
//       path: '/dashboard/digi-esign',
//       element: <DigiESign />
//     },
//     {
//       path: '/dashboard/docusign-container/:documentId',
//       element: <DocusignContainer />
//     },
//     {
//       path: '/dashboard/add-signature/:documentId',
//       element: <AddSignature />
//     },
//     {
//       path: '/dashboard/add-signature-new/:documentId',
//       element: <AddSignatureFabric />
//     },
//     {
//       path: '/dashboard/add-digi-esign/:documentId',
//       element: <AddDigiSign />
//     },
//     // {
//     //   path: '/add-signature-recipient/:documentId',
//     //   element: <AddDigiSign />
//     // },

//     {
//       path: '/dashboard/e-sign',
//       element: <ESignFabric />
//     }
//   ]
// };

const MainRoutes = {
  path: '/dashboard/',
  element: <MainLayoutNewUI />,
  children: [
    {
      path: '/dashboard/home',
      element: <DashboardHome />
    },
    {
      path: '/dashboard/Documents',
      element: <DashboardDocuments />
    },
    {
      path: '/dashboard/create-document',
      element: <DashboardCreateDocument />
    },
    {
      path: '/dashboard/add-digi-esign/:documentId',
      element: <AddSignOnCanvasForAuthUser />
    },
    {
      path: '/dashboard/documents/inbox',
      element: <DocInboxListLazy />
    },
    {
      path: '/dashboard/documents/sent',
      element: <DocSentListLazy />
    },
    {
      path: '/dashboard/documents/completed',
      element: <DocCompletedListLazy />
    },
    {
      path: '/dashboard/documents/archive',
      element: <DocArchiveListLazy />
    },
    {
      path: '/dashboard/documents/trash',
      element: <DocTrashListLazy />
    },
    {
      path: '/dashboard/add-signature-recipient/:documentId',
      // element: <AddDigiSignRecipient />
      element: <AddSignOnCanvasForUnAuthUser />
    },
    {
      path: '/dashboard/account-setting',
      element: <AccountSettingsLazy />
    },
    { path: '/dashboard/notification-setting', element: <NotificationSettingsLazy /> },
    { path: '/dashboard/personal-setting', element: <PersonalSettings /> },
    {
      path: '/dashboard/documents/my-pending-signatures',
      element: <MyPendingSignatures />
    },
    {
      path: '/dashboard/documents/awaiting-signatures',
      element: <MyAwaitingSignature />
    },
    {
      path: '/dashboard/documents/expiring-shortly',
      element: <MyExpiringShortlyDocument />
    },
    {
      path: '/dashboard/documents/expired',
      element: <MyExpiredDocuments />
    }
  ]
};

// const MainRoutes = [
//   {
//     path: '/dashboard/home',
//     element: <DashboardHome />
//   },
//   {
//     path: '/dashboard/Documents',
//     element: <DashboardDocuments />
//   },
//   {
//     path: '/dashboard/create-document',
//     element: <DashboardCreateDocument />
//   },
//   {
//     path: '/dashboard/add-digi-esign/:documentId',
//     element: <AddSignOnCanvasForAuthUser />
//   },
//   {
//     path: '/dashboard/documents/inbox',
//     element: <DocInboxListLazy />
//   },
//   {
//     path: '/dashboard/documents/sent',
//     element: <DocSentListLazy />
//   },
//   {
//     path: '/dashboard/documents/completed',
//     element: <DocCompletedListLazy />
//   },
//   {
//     path: '/dashboard/documents/archive',
//     element: <DocArchiveListLazy />
//   },
//   {
//     path: '/dashboard/documents/trash',
//     element: <DocTrashListLazy />
//   }
// ];

export default MainRoutes;
