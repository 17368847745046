import React, { useState } from 'react';
import OverlayContainer from '../Overlay/OverlayContainer';
import CrossIcon from 'app/assets/icons/CrossIcon';
import EsignAppLogo from '../EsignAppLogo';
import EsignAvatar from '../Avatar/EsignAvatar';
import CheckBoxWithTitle from '../Checkboxes/CheckBoxWithTitle';
import CommonDropdown from '../Dropdowns/CommonDropdown';
import EsignAppPrimaryButton from '../Buttons/EsignAppPrimaryButton';
import { DeclineDocumentPopup } from '../Popups/DeclineDocumentPopup';
import { useParams } from 'react-router';
import useGetAllFunctionsForRecipientUnAuth from 'app/hooks/useGetAllFunctionsForRecipientUnAuth';
const DropdownActions = [
  { title: 'Choose Actions...', type: '' },
  { title: 'Decline', type: 'decline' },
  { title: 'Finish Later', type: 'finishLater' }
];
const ReviewDocumentForRecipient = ({ userInitials, fullName, userColor, onClose }) => {
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [selectedAction, setSelectedAction] = useState('');
  const [openDeclinePopup, setOpenDeclinePopup] = useState(false);
  const { documentId } = useParams();
  const { documentData } = useGetAllFunctionsForRecipientUnAuth();
  const handleActionBasedOnType = () => {
    if (selectedAction?.type === 'finishLater') {
      window.close();
    } else if (selectedAction?.type === 'decline') {
      setOpenDeclinePopup(true);
    }
    //
  };
  const handleOnSelect = (e) => {
    setSelectedAction(e);
    console.log(e, 'eTragetData');
  };
  console.log(documentData, 'documentData');
  return (
    <>
      {documentData?.id && (
        <DeclineDocumentPopup
          open={openDeclinePopup}
          onClose={() => setOpenDeclinePopup(false)}
          isFromCanvas={true}
          documentId={documentData?.id}
        />
      )}

      <OverlayContainer>
        <div
          className="absolute w-[100vw] flex flex-col items-center justify-center top-[-65px] left-[0px] reviewDocRecipientPopup"
          style={{
            height: ' calc(100vh + 65px)'
          }}
        >
          <div className="max-w-[600px] w-full max-h-[260px] h-full bg-[white] z-30 rounded-[10px] p-[20px] flex flex-col gap-[20px]">
            <div className="flex items-center justify-between">
              <EsignAppLogo />
              {/* <button onClick={onClose}>
                <CrossIcon />
              </button> */}
            </div>
            <p className="text-[16px] font-[700] text-[#101010]">Please Review & Act on these Documents</p>
            <EsignAvatar initials={userInitials} fullName={fullName} bgColor={userColor} />
            <CheckBoxWithTitle
              title={'I agree to use electronic records and signatures.'}
              onChange={(e) => {
                if (e?.target?.checked) {
                  setIsBtnDisabled(false);
                } else {
                  setIsBtnDisabled(true);
                }
              }}
            />
            <div className="w-full  flex items-center justify-between gap-[12px] reviewDocForRecipientConsent">
              <CommonDropdown
                items={DropdownActions}
                buttonLabel={selectedAction.title || 'Choose Actions...'}
                className="otherActionsDropdown"
                onSelect={handleOnSelect}
              />
              <EsignAppPrimaryButton
                title={'Continue'}
                onClick={!selectedAction?.type ? onClose : handleActionBasedOnType}
                disabled={!selectedAction?.type ? isBtnDisabled : false}
              />
            </div>
          </div>
        </div>
      </OverlayContainer>
    </>
  );
};

export default ReviewDocumentForRecipient;
