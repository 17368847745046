import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

const CommonFeaturesPageParent = Loadable(lazy(() => import('app/pages/public/FeaturesPage/CommonFeaturesPageParent')));
const TemplatesPage = Loadable(lazy(() => import('app/pages/public/TemplatesPage')));
const SingleBlogContent = Loadable(lazy(() => import('app/pages/public/Blogs/SingleBlogContent')));
const BlogsList = Loadable(lazy(() => import('app/pages/public/Blogs')));
const TermsOfUse = Loadable(lazy(() => import('app/pages/public/TermsOfUse')));
const PrivacyPolicy = Loadable(lazy(() => import('app/pages/public/PrivacyPolicy')));
const RenderCmsPagesCommon = Loadable(lazy(() => import('app/pages/public/RenderCmsPagesCommon')));

export const HeaderRoutes = [
  {
    path: '/features',
    element: <CommonFeaturesPageParent />,
    children: [
      {
        path: '/features/why-its-for',
        element: <CommonFeaturesPageParent />,

        children: [
          {
            path: '/features/why-its-for/banking-financial-institutions',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/features/why-its-for/govt-public-sector',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/features/why-its-for/govt-public-sector',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/features/why-its-for/legal-professionals',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/features/why-its-for/corporate-enterprises',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/features/core-features',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/features/core-features/aadhar-based-authentication',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/features/core-features/legal-compliance-hub',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/features/core-features/smart-workflow-tools',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/features/core-features/microsoft-integration',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/features/core-features/template-management',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/features/core-features/enterprise-integration',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/features/competitive-edge',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/features/digital-transformation-impact'
      }
    ]
  },
  {
    path: '/workflows',
    element: <CommonFeaturesPageParent />,
    children: [
      {
        path: '/workflows/smart-approval-chains',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/workflows/automated-document-management',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/workflows/role-based-controls',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/workflows/template-library',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/workflows/workflow-automation-features',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/workflows/batch-processing',
        element: <CommonFeaturesPageParent />
      }
    ]
  },
  {
    path: '/integrations',
    element: <CommonFeaturesPageParent />,
    children: [
      {
        path: '/integrations/lexops-platform-integration',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/integrations/enterprise-grade-integration',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/integrations/enterprise-grade-integration/comprehensive-rest-api',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/integrations/enterprise-grade-integration/auth-security',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/integrations/enterprise-grade-integration/advanced-logging',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/integrations/seamless-microsoft-integration',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/integrations/seamless-microsoft-integration/word-addin',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/integrations/seamless-microsoft-integration/sharedpoint-connection',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/integrations/seamless-microsoft-integration/team-integration',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/integrations/additional-integrations',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/integrations/additional-integrations/aadhar-integration',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/integrations/additional-integrations/api-protocol-support',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/integrations/additional-integrations/contract-lifecycle-management',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/integrations/compliance-database-integration',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/integrations/regulatory-database-integrations',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/integrations/additional-features',
        element: <CommonFeaturesPageParent />
      }
    ]
  },
  {
    path: '/contracts',
    element: <CommonFeaturesPageParent />,
    children: [
      {
        path: '/contracts/smart-contract-management',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/contracts/vendor-management-tools',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/contracts/purchase-order-automation',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/contracts/sales-contract-acceleration',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/contracts/compliance-control',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/contracts/analytics-reporting',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/contracts/collaboration-features',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/contracts/integration-capabilities',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/contracts/physical-digital-delivery-of-contracts-for-execution',
        element: <CommonFeaturesPageParent />
      }
    ]
  },
  {
    path: '/human-resources',
    element: <CommonFeaturesPageParent />,
    children: [
      {
        path: '/human-resources/employee-onboarding',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/human-resources/employee-onboarding/offer-management',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/human-resources/employee-onboarding/documentation-bundle',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/human-resources/hr-forms-automation',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/human-resources/hr-forms-automation/employee-data-collection',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/human-resources/hr-forms-automation/compliance-documents',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/human-resources/employee-lifecycle-management',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/human-resources/employee-lifecycle-management/regular-hr-process',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/human-resources/employee-lifecycle-management/exit-processing',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/human-resources/process-efficiency',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/human-resources/process-efficiency/smart-templates',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/human-resources/process-efficiency/workflow-automation',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/human-resources/integration-capabilities',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/human-resources/integration-capabilities/hrms-connection',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/human-resources/integration-capabilities/analytics-reporting',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/human-resources/security-privacy',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/human-resources/security-privacy/data-protection',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/human-resources/physical-digital-delivery',
        element: <CommonFeaturesPageParent />
      }
    ]
  },

  {
    path: '/gov-regulatory-&-compliance',
    element: <CommonFeaturesPageParent />,
    children: [
      {
        path: '/gov-regulatory-&-compliance/digital-compliance-management',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/gov-regulatory-&-compliance/digital-compliance-management/regulatory-filings',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/gov-regulatory-&-compliance/digital-compliance-management/license-management',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/gov-regulatory-&-compliance/stamp-duty-processing',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/gov-regulatory-&-compliance/stamp-duty-processing/digital-stamping',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/gov-regulatory-&-compliance/stamp-duty-processing/document-registration',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/gov-regulatory-&-compliance/government-applications',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/gov-regulatory-&-compliance/government-applications/application-processing',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/gov-regulatory-&-compliance/government-applications/department-integration',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/gov-regulatory-&-compliance/security-applications',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/gov-regulatory-&-compliance/security-applications/digital-signatures',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/gov-regulatory-&-compliance/security-applications/document-security',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/gov-regulatory-&-compliance/compliance-features',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/gov-regulatory-&-compliance/compliance-features/regulatory-framework',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/gov-regulatory-&-compliance/compliance-features/record-keeping',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/gov-regulatory-&-compliance/physical-and-digital-delivery',
        element: <CommonFeaturesPageParent />
      }
    ]
  },

  {
    path: '/banking-insurance-&-real-estate',
    element: <CommonFeaturesPageParent />,
    children: [
      {
        path: '/banking-insurance-&-real-estate/banking-document-processing',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/banking-insurance-&-real-estate/banking-document-processing/loan-application-automation',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/banking-insurance-&-real-estate/banking-document-processing/account-management',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/banking-insurance-&-real-estate/insurance-documentation',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/banking-insurance-&-real-estate/insurance-documentation/policy-management',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/banking-insurance-&-real-estate/insurance-documentation/claims-processing',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/banking-insurance-&-real-estate/real-estate-transactions',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/banking-insurance-&-real-estate/real-estate-transactions/mortgage-processing',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/banking-insurance-&-real-estate/real-estate-transactions/property-documentation',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/banking-insurance-&-real-estate/privacy-security',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/banking-insurance-&-real-estate/privacy-security/data-protection',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/banking-insurance-&-real-estate/privacy-security/compliance-features',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/banking-insurance-&-real-estate/process-efficiency',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/banking-insurance-&-real-estate/process-efficiency/automated-workflows',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/banking-insurance-&-real-estate/process-efficiency/integration-capabilities',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/banking-insurance-&-real-estate/physical-and-digital-delivery',
        element: <CommonFeaturesPageParent />
      }
    ]
  },
  {
    path: '/document-delivery-&-tracking',
    element: <CommonFeaturesPageParent />,
    children: [
      {
        path: '/document-delivery-&-tracking/physical-delivery',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/document-delivery-&-tracking/physical-delivery/courier-services-integration',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/document-delivery-&-tracking/physical-delivery/postal-services',
            element: <CommonFeaturesPageParent />
          }
        ]
      },

      {
        path: '/document-delivery-&-tracking/digital-distribution',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/document-delivery-&-tracking/digital-distribution/electronic-channels',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/document-delivery-&-tracking/real-time-tracking',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/document-delivery-&-tracking/real-time-tracking/delivery-monitoring',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/document-delivery-&-tracking/real-time-tracking/proof-of-delivery',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/document-delivery-&-tracking/reporting-&-analytics',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/document-delivery-&-tracking/reporting-&-analytics/mis-reporting',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/document-delivery-&-tracking/reporting-&-analytics/export-capabilities',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/document-delivery-&-tracking/compliance-&-audit',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/document-delivery-&-tracking/compliance-&-audit/audit-management',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/document-delivery-&-tracking/automated-follow-up',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/document-delivery-&-tracking/automated-follow-up/action-management',
            element: <CommonFeaturesPageParent />
          }
        ]
      }
    ]
  },
  {
    path: '/integration',
    element: <CommonFeaturesPageParent />,
    children: [
      {
        path: '/integration/lexops-platform-integration',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/integration/enterprise-grade-integration',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/integration/enterprise-grade-integration/comprehensive-rest-api',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/integration/enterprise-grade-integration/authentication-&-security',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/integration/enterprise-grade-integration/advanced-logging',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/integration/seamless-microsoft-integration',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/integration/seamless-microsoft-integration/word-addin',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/integration/seamless-microsoft-integration/sharepoint-connection',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/integration/seamless-microsoft-integration/team-integration',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/integration/additional-integrations',
        element: <CommonFeaturesPageParent />,
        children: [
          {
            path: '/integration/additional-integrations/aadhar-integration',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/integration/additional-integrations/api-protocol-support',
            element: <CommonFeaturesPageParent />
          },
          {
            path: '/integration/additional-integrations/contract-lifecycle-management',
            element: <CommonFeaturesPageParent />
          }
        ]
      },
      {
        path: '/integration/compliance-database-integration',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/integration/regulatory-database-integrations',
        element: <CommonFeaturesPageParent />
      },
      {
        path: '/integration/additional-features',
        element: <CommonFeaturesPageParent />
      }
    ]
  },
  {
    path: '/templates',
    element: <TemplatesPage />
  },
  {
    path: '/blogs',
    element: <BlogsList />
  },
  {
    path: '/blogs/:slug',
    element: <SingleBlogContent />
  },
  {
    path: '/terms-of-use',
    element: <TermsOfUse />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  }
];
