import CrossIcon from 'app/assets/icons/CrossIcon';
import DeclineIcon from 'app/assets/icons/DeclineIcon';
import { Button, Checkbox, Label, Modal, Textarea, TextInput } from 'flowbite-react';
import { useEffect, useState } from 'react';
import EsignAppPrimaryButton from '../../Buttons/EsignAppPrimaryButton';
import EsignAppSecondaryButton from '../../Buttons/EsignAppSecondaryButton';
import { getRecipientList, updateDocumentStatus, updateExpirationSetting } from 'services/eSignService';
import { useDispatch } from 'react-redux';
import { setEsignMainAppLoader, setRecipientExpiryData } from 'store/esignMainSlice';
import { useAccount } from 'context/AccountProvider';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { RecipientDropdownActionsConst } from 'utils/esign.helper';
import MarkAsInvalidIcon from 'app/assets/icons/MarkAsInvalidIcon';
import PriorityLevelVeryHigh from './PriorityLevel/PriorityLevelVeryHigh';
import SetDurationDateAndTime from 'app/components/Calender/SetDurationDateAndTime';
import { formatTimeDifference, priorityLevelConst } from 'app/helper/selectTime.const';
import { useSelector } from 'react-redux';
import PriorityLevelHigh from './PriorityLevel/PriorityLevelHigh';
import PriorityLevelMedium from './PriorityLevel/PriorityLevelMedium';
import PriorityLevelLow from './PriorityLevel/PriorityLevelLow';
import PriorityLevelEmpty from './PriorityLevel/PriorityLevelEmpty';
import { EditValidityPopupTable } from './EditValidityPopupTable';
import ApprovedText from 'app/components/Documents/DocumentList/DocumentStatusTexts/ApprovedText';
import StatusTextWithIcon from 'app/components/Documents/DocumentList/InboxStatus/StatusTextWithIcon';
import SignedByYouIcon from 'app/assets/icons/SignedByYouIcon';
import ApprovedIcon from 'app/assets/icons/ApprovedIcon';
import PendingStatusIcon from 'app/assets/icons/PendingStatusIcon';
import dayjs from 'dayjs';

export function EditValidityPopup({ open, onClose, currentItem }) {
  const dispatch = useDispatch();

  const recipientList = useSelector((state) => state.main.recipientList);
  const expiryDataRdx = useSelector((state) => state.esignMain.recipientExpiryData);
  const [expiryData, setExpiryData] = useState([]);
  const {
    recipient_configs,
    _id: { $oid: documentId }
  } = currentItem;
  const [expiryPreviousData, setExpiryPreviousData] = useState({});
  const handleApply = async (item) => {
    const { id: email } = item;
    const expiryItem = expiryData.filter((item) => {
      return item?.id === email;
    });
    if (expiryItem.length > 0) {
      const expirtyData = expiryItem?.[0];
      if (expirtyData?.date || expirtyData?.hours || expirtyData?.minutes || expirtyData?.amPm) {
        const date = expirtyData?.date || new Date().toISOString().split('T')[0];
        const hours = expirtyData?.hours || '00';
        const minutes = expirtyData?.minutes || '00';
        const amPm = expirtyData?.amPm || 'AM';
        const payload = {
          email: email,
          expiry_date_time: `${date} ${hours}:${minutes} ${amPm}`
        };
        try {
          const data = await updateExpirationSetting(documentId, payload, (value) => dispatch(setEsignMainAppLoader(value)));
          if (data?.message) {
            toast.success(data.message);
            getRecipientListData();
          }
        } catch (error) {
          toast.error(error.message);
        }
      }
    }
  };
  const handleClear = async (item) => {
    const { id: email } = item;
    let currentItem = [];
    const expiryItem = expiryData.map((item) => {
      if (item?.id !== email) {
        return item;
      } else {
        return {
          ...item,
          date: '',
          hours: '',
          minutes: '',
          id: item.id
        };
      }
    });
    setExpiryData(expiryItem);
    console.log(email, item, expiryItem, currentItem, 'emailData');
  };
  const recipientListData = expiryPreviousData?.recipient_configs || [];
  const handleExpiryData = (item, key, value) => {
    const { id } = item;

    if (key === 'date') {
      const newData = expiryData.map((dataItem) => (dataItem.id === id ? { ...dataItem, [key]: value } : dataItem));
      setExpiryData(newData);
    } else if (key === 'amPm') {
      const newData = expiryData.map((dataItem) => (dataItem.id === id ? { ...dataItem, [key]: value, hours: '', minutes: '' } : dataItem));
      setExpiryData(newData);
    } else {
      const newData = expiryData.map((dataItem) => (dataItem.id === id ? { ...dataItem, [key]: value } : dataItem));
      setExpiryData(newData);
    }

    // dispatch(setRecipientExpiryData(newData));
  };
  useEffect(() => {
    if (recipientListData.length) {
      const initialData = recipientListData.map((item) => {
        return {
          date: '',
          hours: '',
          minutes: '',
          amPm: 'PM',
          previousTime: item?.expiry_date_time,
          id: item.id,
          status: item.status
        };
      });
      console.log(recipientListData, initialData, 'recipientListData22');
      setExpiryData(initialData);
    }
  }, [recipientListData]);

  const renderPriorityLevel = (item) => {
    let previousDate;
    if (item?.date) {
      previousDate = `${item?.date} ${item?.hours || '00'}:${item?.minutes || '00'} ${item?.amPm || 'PM'}`;
    } else {
      previousDate = new Date(item?.date || item?.previousTime);
    }

    const diffInHours = dayjs(previousDate).diff(new Date(), 'hour');
    console.log(previousDate, diffInHours, 'renderPriorityLevel');

    if (diffInHours <= 6) {
      return <PriorityLevelVeryHigh />;
    } else if (diffInHours > 6 && diffInHours <= 24) {
      return <PriorityLevelHigh />;
    } else if (diffInHours > 24 && diffInHours <= 72) {
      return <PriorityLevelMedium />;
    } else if (diffInHours > 72 && diffInHours <= 168) {
      return <PriorityLevelLow />;
    } else {
      return <PriorityLevelEmpty />;
    }
  };

  const renderStatusColumn = (item) => {
    if (item?.status === RecipientDropdownActionsConst.NeedsToApprove) {
      return <StatusTextWithIcon title={'Approved'} Icon={ApprovedIcon} textColor={'#3B82F6'} />;
    } else if (item?.status === RecipientDropdownActionsConst.NeedsToSign) {
      return <StatusTextWithIcon title={'Signed'} Icon={SignedByYouIcon} textColor={'#2E844A'} />;
    } else {
      return <StatusTextWithIcon title={'Pending'} Icon={PendingStatusIcon} textColor={'#EAB308'} />;
    }
  };
  const renderCurrentDurationLeftColumn = (item) => {
    return <h4 className="text-[#030712] font-[500] text-[14px]">{formatTimeDifference(item?.expiry_date_time)}</h4>;
  };

  const getRecipientListData = async () => {
    try {
      const data = await getRecipientList({ document_id: documentId }, (data) => dispatch(setEsignMainAppLoader(data)));
      const newData = data?.recipient_configs.map?.((item) => {
        return {
          ...item,
          id: item.email
        };
      });
      setExpiryPreviousData({ ...data, recipient_configs: newData || [] });
      console.log(newData, data, 'recipientListdata');
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getRecipientListData();
  }, []);

  const renderCommonColumns = (item, cb) => {
    if (item?.status === RecipientDropdownActionsConst.NeedsToApprove) {
      return <h4 className="text-[#030712] font-[500] text-[14px]">-</h4>;
    } else if (item?.status === RecipientDropdownActionsConst.NeedsToSign) {
      return <h4 className="text-[#030712] font-[500] text-[14px]">-</h4>;
    }
    return cb(item);
  };

  console.log(currentItem, expiryData, recipientListData, 'currentItem');

  return (
    <>
      <Modal show={open} size="7xl" onClose={onClose} popup theme={theme}>
        {/* <Modal.Header /> */}
        <Modal.Body>
          <div className="p-[20px]">
            <div className="w-full flex items-start justify-between">
              <div className="flex items-start gap-[12px]">
                <div className="w-full">
                  <div>
                    <h3 className="text-[16px] font-[700] text-[#101010] mb-[4px]">Edit Validity</h3>
                    <p className="text-[14px] font-[400] text-[#6B7280] ">
                      The expiration is set to 48 hours by default. However, if you'd like to customize the expiration time, you can specify
                      it below.
                    </p>
                  </div>
                  {/* <EditValidityPopupTable /> */}
                  <div className="grid grid-cols-20 mt-[20px] gap-[20px] ">
                    <div className="col-span-4">
                      <h4 className="text-[14px] font-[600] text-[#030712]">Recipients</h4>
                      <div className="flex gap-[10px] flex-col justify-center mt-[8px]">
                        {recipientListData.map((item, i) => {
                          return (
                            <div
                              className="h-[34px]  border-[1px] border-[#D4D4D8] rounded-[6px] flex items-center px-[12px] text-[14px] font-[400] text-[#030712]"
                              key={item?.id}
                            >
                              {/* <span className="pr-[6px]">{i + 1}.</span> */}
                              <span className="">{item?.name}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="w-100% col-span-3">
                      <h4 className="text-[14px] font-[600] text-[#030712]">Priority Level</h4>
                      <div className="flex gap-[6px] flex-col justify-center mt-[8px] ">
                        {expiryData.map((item, i) => {
                          return renderCommonColumns(item, renderPriorityLevel);
                        })}
                      </div>
                    </div>
                    <div className="w-100% col-span-3">
                      <h4 className="text-[14px] font-[600] text-[#030712]">Status</h4>
                      <div className="flex gap-[6px] flex-col justify-center mt-[8px] ">
                        {recipientListData.map((item, i) => {
                          return <div className="h-[34px] flex  items-center">{renderStatusColumn(item)}</div>;
                        })}
                      </div>
                    </div>
                    <div className="w-100% col-span-5">
                      <h4 className="text-[14px] font-[600] text-[#030712]">Current Duration Left</h4>
                      <div className="flex gap-[6px] flex-col justify-center mt-[8px] ">
                        {recipientListData.map((item, i) => {
                          return (
                            <div className="h-[34px] flex  items-center">{renderCommonColumns(item, renderCurrentDurationLeftColumn)}</div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="w-[100%] col-span-5">
                      <h4 className="text-[14px] font-[600] text-[#030712]">Set New Duration</h4>
                      <div className="flex gap-[6px] flex-col justify-center mt-[8px]">
                        {expiryData.map((item, i) => {
                          return renderCommonColumns(item, (item) => {
                            return (
                              <>
                                <SetDurationDateAndTime
                                  handleExpiryData={handleExpiryData}
                                  expiryData={expiryData}
                                  item={item}
                                  editButtons={
                                    <>
                                      <button
                                        className="bg-[#F0F9FF] text-[#3B82F6] h-[36px] rounded-[6px] px-[20px]"
                                        onClick={() => handleApply(item)}
                                      >
                                        Apply
                                      </button>
                                      <button onClick={() => handleClear(item)}>Clear</button>
                                    </>
                                  }
                                />
                              </>
                            );
                          });
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button onClick={onClose}>
                <CrossIcon />
              </button>
            </div>
            {/* <div className="w-full flex items-end justify-end flex-col ">
              <div className="mt-[20px] flex items-center gap-[10px]">
                {<EsignAppSecondaryButton title={'Clear'} onClick={handleApply} />}
                {<EsignAppPrimaryButton title={'Apply'} onClick={onClose} />}
              </div>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const theme = {
  root: {
    base: 'fixed inset-x-0 top-0 z-[9999] h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full',
    show: {
      on: 'flex bg-[black] bg-opacity-50',
      off: 'hidden'
    },
    sizes: {
      sm: 'max-w-sm',
      md: 'max-w-md',
      lg: 'max-w-lg',
      xl: 'max-w-xl',
      '2xl': 'max-w-2xl',
      '3xl': 'max-w-3xl',
      '4xl': 'max-w-4xl',
      '5xl': 'max-w-5xl',
      '6xl': 'max-w-6xl',
      '7xl': 'max-w-7xl'
    },
    positions: {
      'top-left': 'items-start justify-start',
      'top-center': 'items-start justify-center',
      'top-right': 'items-start justify-end',
      'center-left': 'items-center justify-start',
      center: 'items-center justify-center',
      'center-right': 'items-center justify-end',
      'bottom-right': 'items-end justify-end',
      'bottom-center': 'items-end justify-center',
      'bottom-left': 'items-end justify-start'
    }
  },
  content: {
    base: 'relative h-full w-full p-4 md:h-auto w-auto max-w-screen',
    inner: 'relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700 max-w-screen'
  },
  body: {
    base: 'flex-1 overflow-auto',
    popup: 'pt-0'
  },
  header: {
    base: 'flex items-start justify-between rounded-t border-b p-5 dark:border-gray-600',
    popup: 'border-b-0 p-2',
    title: 'text-xl font-medium text-gray-900 dark:text-white',
    close: {
      base: 'ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white',
      icon: 'h-5 w-5'
    }
  },
  footer: {
    base: 'flex items-center space-x-2 rounded-b border-gray-200 p-6 dark:border-gray-600',
    popup: 'border-t'
  }
};
