import EsignValueRemoveSignBtn from 'app/components/Buttons/EsignValueRemoveSignBtn';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPlaceHolderValue } from 'store/mainSlice';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { PlaceHolderTypes, replacePlaceholderwithImage } from 'utils/esign.helper';
import CustomSignTemplate from './CustomSignTemplate';
import { useSelector } from 'react-redux';
import useGetUserPreferences from 'hooks/useGetUserPreferences';
import { setEsignSelectedSignType } from 'store/esignMainSlice';
import SignConsentPopup from 'app/components/Consent/SignConsentPopup';

const EsignUseMySignWithTemplate = ({ canvasRdxAction = selectReplaceCanvasAction }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [fileSelected, setFileSelected] = useState(false);
  const [previewImageWithContainer, setPreviewImageWithContainer] = useState(null);
  const [isShowConsent, setIsShowConsent] = useState(true);
  const canvas = useSelector(canvasRdxAction);
  const dispatch = useDispatch();
  const { signature } = useGetUserPreferences();
  const handleClearPreview = () => {
    setPreviewImage(null);
    setFileSelected(false); // Reset the flag when clearing the preview
  };

  const handleSave = () => {
    replacePlaceholderwithImage(previewImageWithContainer, canvas, (img, enhancedImageResolution) => {
      dispatch(setPlaceHolderValue({ value: { id: img?.id, addedValue: enhancedImageResolution, actionType: PlaceHolderTypes.sign } }));
    });

    dispatch(setEsignSelectedSignType(''));
  };
  const placeholderWidth = canvas?.getActiveObject()?.width;
  const placeholderHeight = canvas?.getActiveObject()?.height;
  return (
    <div className="mt-[10px] w-full">
      <CustomSignTemplate
        width={placeholderWidth}
        height={placeholderHeight}
        imageUrl={signature}
        cb={(imageurl) => {
          setPreviewImageWithContainer(imageurl);
        }}
      />
      {isShowConsent ? <>{<SignConsentPopup onSignClick={handleSave} onCancel={() => setIsShowConsent(false)} />}</> : null}
    </div>
  );
};

export default EsignUseMySignWithTemplate;
