import StartEsignWorkflow from 'app/components/Buttons/StartEsignWorkflow';
import { useAccount } from 'context/AccountProvider';
import React from 'react';

const StartFreeTrialButton = () => {
  const { userDetails } = useAccount();
  return (
    <>
      {userDetails?.email ? (
        <div className="w-fit mt-[20px] p-[5px] rounded-[6px] ">
          <StartEsignWorkflow />
        </div>
      ) : (
        <button className="bg-[#3B82F6] border-[2px] border-[#3B82F64D] rounded-[10px] h-[48px] px-[20px] text-[14px] font-[600] text-[white] w-fit mt-[20px]">
          Speak to our Agent
        </button>
      )}
    </>
  );
};

export default StartFreeTrialButton;
