import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import SignConsentPopup from '../Consent/SignConsentPopup';

const EsignValueRemoveSignBtn = ({
  onSignOnClick,
  onRemoveOnClick,
  isSignBtnDisabled,
  isRemoveBtnDisabled,
  primaryBtnLabel,
  isShowConsent = true
}) => {
  const [showConsent, setShowConsent] = useState(false);
  console.log(isShowConsent, showConsent, 'isShowConsent');
  return (
    <>
      {isShowConsent ? (
        <>{showConsent ? <SignConsentPopup onSignClick={onSignOnClick} onCancel={() => setShowConsent(false)} /> : <></>}</>
      ) : null}

      <div className="w-full flex items-center justify-evenly max-w-full">
        <button className="text-[#DC2626] text-[14px] font-[500]  w-[80%]" onClick={onRemoveOnClick} disabled={isRemoveBtnDisabled}>
          Clear
        </button>
        <button
          className="bg-[#F0F9FF] text-[#3B82F6] font-[600] text-[14px] rounded-[6px] py-[10px] px-[20px] w-[100%]"
          onClick={() => {
            if (isShowConsent) {
              setShowConsent(true);
            } else {
              onSignOnClick();
            }
          }}
          disabled={isSignBtnDisabled}
        >
          {primaryBtnLabel || 'Sign'}
        </button>
      </div>
    </>
  );
};

export default EsignValueRemoveSignBtn;
