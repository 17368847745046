import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { PlaceHolderTypes } from 'utils/esign.helper';

const useCanvasNavigatePlaceholders = () => {
  const [signMarkerTop, setSignMarkerTop] = useState(0);
  const canvas = useSelector(selectReplaceCanvasAction);
  const [sortedPlaceholderList, setSortedPlaceholderList] = useState(null);
  const [currentSignMarkIndex, setCurrentSignMarkIndex] = useState(0);

  const totalPlaceholderLength = useMemo(() => Object.keys(sortedPlaceholderList || {}).length, [sortedPlaceholderList]);

  const currentPlaceholder = sortedPlaceholderList?.[currentSignMarkIndex];

  const goToSignMarker = useCallback(
    (index) => {
      if (index < 0 || index >= totalPlaceholderLength) return;

      const canvasParentContainer = document.getElementById('esign_document_canvas_container');
      const topValue = sortedPlaceholderList[index]?.top;

      if (topValue !== undefined) {
        setSignMarkerTop(topValue);
        canvasParentContainer.scroll({
          top: topValue,
          behavior: 'smooth'
        });
        setCurrentSignMarkIndex(index);
      }
    },
    [sortedPlaceholderList, totalPlaceholderLength]
  );

  const goNext = useCallback(() => {
    const nextIndex = currentSignMarkIndex + 1;
    if (nextIndex < totalPlaceholderLength) {
      goToSignMarker(nextIndex);
    } else {
      goToSignMarker(0);
      setCurrentSignMarkIndex(0);
    }
  }, [currentSignMarkIndex, totalPlaceholderLength, goToSignMarker]);

  const goPrev = useCallback(() => {
    const prevIndex = currentSignMarkIndex - 1;
    if (prevIndex >= 0) {
      goToSignMarker(prevIndex);
    }
  }, [currentSignMarkIndex, goToSignMarker]);

  useEffect(() => {
    if (canvas !== null) {
      const onAfterRender = () => {
        if (!sortedPlaceholderList) {
          const placeholders = canvas?.getObjects()?.filter((item) => Object.values(PlaceHolderTypes).includes(item?.actionType));

          if (placeholders?.length) {
            const sortedData = placeholders.sort((a, b) => a.top - b.top);
            const placeholderList = sortedData.map((item, index) => ({
              top: item?.top,
              count: index + 1
            }));
            setSortedPlaceholderList(placeholderList);
          }
        }
      };

      canvas.on('after:render', onAfterRender);

      return () => {
        canvas.off('after:render', onAfterRender);
      };
    }
  }, [canvas, sortedPlaceholderList]);

  return {
    signMarkerTop,
    totalPlaceholderLength,
    currentPlaceholder,
    goNext,
    goPrev,
    goToSignMarker
  };
};

export default useCanvasNavigatePlaceholders;
