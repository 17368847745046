import {
  allHoursConst,
  allHoursConstForAM,
  allHoursConstForPM,
  getExpirtyModuleDateFormat,
  minutesArrayConst
} from 'app/helper/selectTime.const';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import DateFnsAdapter from '@date-io/date-fns';
import dayjs from 'dayjs';

// import { isFuture } from 'date-fns';
const Item = ({ text, active, onClick, disabled, ...props }) => {
  return (
    <button
      className={classNames('text-[14px] font-[500] text-[#030712] h-[36px] rounded-[6px] flex items-center justify-center px-[4px]', {
        'bg-[#3B82F6] text-[white]': active,
        'cursor-not-allowed opacity-50': disabled
      })}
      onClick={!disabled ? onClick : null}
      {...props}
    >
      {text}
    </button>
  );
};

const CustomSelectTime = ({ hours, minutes, amPm, handleExpiryData, currentItem, date, expiryData }) => {
  const [hoursList, setHoursList] = useState([]);
  const { previousTime } = expiryData;
  const diffDateHours = dayjs(`${date} ${amPm === 'AM' ? '00' : '12'}:${'00'} ${amPm || 'PM'}`).diff(new Date(), 'hour');

  let allHoursArray = [];
  const leftHours = diffDateHours + 12;
  for (let hoursInd = 12 - leftHours + 1; hoursInd < 12; hoursInd++) {
    const element = hoursInd.toString().length === 1 ? `0${hoursInd}` : `${hoursInd}`;
    allHoursArray.push(element);
  }

  useEffect(() => {
    if (amPm === 'AM') {
      setHoursList(allHoursConstForAM);
    } else {
      setHoursList(allHoursConstForPM);
    }
  }, [amPm]);

  console.log(diffDateHours, allHoursArray, amPm, 'diffDateHours');
  return (
    <div className="relative">
      <div className="w-[150px] rounded-[6px] bg-[white] shadow-lg grid grid-cols-3 p-[10px] gap-[9px]">
        {/* Hour Selector */}
        <div className="max-h-[200px] overflow-auto selectTimeCustomScrollbar">
          {hoursList.map((item) => {
            const notDisabled = allHoursArray.includes(item.value);
            return (
              <Item
                key={item.value}
                text={item.title}
                active={item.value === hours}
                onClick={() => {
                  console.log('Hour Selected:', item.value);
                  handleExpiryData(currentItem, 'hours', item.value);
                }}
                disabled={!notDisabled}
              />
            );
          })}
        </div>

        {/* Minute Selector */}
        <div className="max-h-[200px] overflow-auto selectTimeCustomScrollbar">
          {minutesArrayConst.map((item) => {
            // const disabled = isMinuteDisabled(item.value, hours);
            return (
              <Item
                key={item.value}
                text={item.title}
                active={item.value === minutes}
                onClick={() => {
                  console.log('Minute Selected:', item.value);
                  handleExpiryData(currentItem, 'minutes', item.value);
                }}
                disabled={!hours}
              />
            );
          })}
        </div>

        {/* AM/PM Selector */}
        <div className="max-h-[200px] overflow-y-auto">
          <Item
            text={'AM'}
            active={'AM' === amPm}
            onClick={() => {
              console.log('AM Selected');
              handleExpiryData(currentItem, 'amPm', 'AM');
            }}
            // disabled={isAM}
          />
          <Item
            text={'PM'}
            active={'PM' === amPm}
            onClick={() => {
              console.log('PM Selected');
              handleExpiryData(currentItem, 'amPm', 'PM');
            }}
            // disabled={!isAM}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomSelectTime;
