import { useAccount } from 'context/AccountProvider';
import React from 'react';
import { useSelector } from 'react-redux';

const useIsCurrentUserFilledAllValueDocument = () => {
  const { userDetails } = useAccount();
  const uploadedDocumentAllData = useSelector((state) => state.esignMain.uploadedDocumentAllData);
  const isSubmitted = uploadedDocumentAllData?.recipient_configs
    ?.filter?.((item) => item?.email === userDetails?.email)
    .every((item) => {
      return item?.status;
    });
  return isSubmitted;
};

export default useIsCurrentUserFilledAllValueDocument;
