import CommonDropdown from 'app/components/Dropdowns/CommonDropdown';
import FreeTextSign from 'layout/MainLayoutNewUI/Esign/replaceCanvas/RightSideBar/signatures/FreeTextSign';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectUsersNodes, setEsignMainAppLoader, setEsignSelectedSignType, setSelectedActiveObject } from 'store/esignMainSlice';
import { selectReplaceCanvasAction } from 'store/replaceCanvasReducer';
import { divideTextBySpace, PlaceHolderTypes, replacePlaceholderwithImage } from 'utils/esign.helper';
import DrawSign from 'views/digiEsign/addDigiSign/RightSidebarActionPanel/Signature/DrawSign';
import UploadSign from 'views/digiEsign/addDigiSign/RightSidebarActionPanel/Signature/UploadSign';
import EsignUploadSign from './SignActionsList/EsignUploadSign';
import EsignAvatar from 'app/components/Avatar/EsignAvatar';
import EsignFreeTextSign from './SignActionsList/EsignFreeTextSign';
import RemovePlaceholderValue from '../RemovePlaceholderValue';
import EsignAddInitials from './SignActionsList/EsignAddInitials';
import EsignAddName from './SignActionsList/EsignAddName';
import EsignAddMobileNumber from './SignActionsList/EsignAddMobileNumber';
import EsignAddDesignation from './SignActionsList/EsignAddDesignation';
import EsignAddEmail from './SignActionsList/EsignAddEmail';
import EsignAddDate from './SignActionsList/EsignAddDate';
import { aadharSignByVsign, getUserPreferences, getUserSignature } from 'services/eSignService';
import { setPlaceHolderValue } from 'store/mainSlice';
import UserDetails from 'views/pages/OrgSubscription/UsersTab/UserDetails';
import { useAccount } from 'context/AccountProvider';
import { useParams } from 'react-router';
import EsignAppAssets from 'app/assets';
import useIsCurrentUserFilledAllValueDocument from 'app/hooks/useIsCurrentUserFilledAllValueDocument';
import useGetUserPreferences from 'hooks/useGetUserPreferences';
import EsignUseMySignWithTemplate from './SignActionsList/EsignUseMySignWithTemplate';
export const AllSIGNTYPES = [
  { title: 'Upload Sign', type: 'upload' },
  // { title: 'Use your Sign' },
  { title: 'Draw Sign', type: 'draw' },
  { title: 'Free Text Sign', type: 'text_sign' }
  // { title: 'Aadhar Sign', type: 'aadhar' }
];
const RightSideSignNPlaceholderValueAdd = () => {
  const usersNodes = useSelector(selectUsersNodes);
  const canvas = useSelector(selectReplaceCanvasAction);
  const selectedActiveObject = useSelector((state) => state.esignMain.selectedActiveObject);
  const esignSelectedSignType = useSelector((state) => state.esignMain.esignSelectedSignType);
  const dispatch = useDispatch();
  const selectedSign = useSelector((state) => state.esignMain.selectedActiveObject);
  const [allSignTypeList, setAllSignTypeList] = useState(AllSIGNTYPES);
  const { userDetails } = useAccount();
  const { documentId } = useParams();
  const vsignFormRefBtn = useRef(null);
  const vsignFormInputRef = useRef('');
  const uploadedDocumentAllData = useSelector((state) => state.esignMain.uploadedDocumentAllData);
  const isAllThingsSubmitted = useIsCurrentUserFilledAllValueDocument();
  const { signature, initials: uploadedInitial } = useGetUserPreferences();
  useEffect(() => {
    if (canvas !== null) {
      canvas.on('selection:created', (e) => {
        console.log(e, 'selected content');
        const selectedItem = e?.selected?.[0];
        // setSelectedSignType(selectedItem);
        dispatch(setSelectedActiveObject(selectedItem));
      });
      canvas.on('selection:updated', (e) => {
        const selectedItem = e?.selected?.[0];
        console.log(e, 'selected content');
        dispatch(setSelectedActiveObject(selectedItem));
      });
      canvas.on('selection:cleared', () => {
        dispatch(setSelectedActiveObject(null));
      });
    }
  }, [canvas]);
  console.log(usersNodes, 'usersNodes');
  const getUserFullNameInitials = () => {
    const firstName = divideTextBySpace(usersNodes?.[0]?.userFullName)?.[0];
    const lastName = divideTextBySpace(usersNodes?.[0]?.userFullName)?.[1];
    return `${firstName?.[0] || ''}${lastName?.[0] || ''}`;
  };

  const handleReplaceUseYourSign = (img) => {
    replacePlaceholderwithImage(img, canvas, (img) => {
      dispatch(setPlaceHolderValue({ value: { id: img?.id, addedValue: img.toDataURL(), actionType: PlaceHolderTypes.sign } }));
    });
  };

  const handleOnSelect = (data) => {
    // console.log(data, 'signType');
    // if (data?.type === 'use_your_sign') {
    //   handleReplaceUseYourSign(data?.img);
    // } else {
    //   dispatch(setEsignSelectedSignType(data));
    // }
    dispatch(setEsignSelectedSignType(data));
  };
  console.log(canvas?.getObjects(), canvas?.getActiveObject(), 'canvasobjectdebug');

  const handleAuthWithAadhar = async () => {
    try {
      if (vsignFormRefBtn !== null) {
        const payload = {
          signer_name: `${userDetails?.first_name} ${userDetails?.last_name}`,
          reason: 'Authenticate with digiesign',
          document_id: documentId,
          user_id: userDetails?.username,
          email: userDetails?.email
        };
        const api = await aadharSignByVsign(payload);
        vsignFormInputRef.current.value = api;
        if (vsignFormRefBtn.current) {
          vsignFormRefBtn.current.click();
        }
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const showPreview = () => {
    switch (esignSelectedSignType?.type) {
      case 'upload':
        return <div className="interation_content_preview">{<EsignUploadSign />}</div>;
        break;
      case 'draw':
        return <div className="interation_content_preview">{<DrawSign />}</div>;
        break;
      // case 'aadhar':
      //   return (
      //     <>
      //       <button
      //         className=" text-[#216DDE] w-full h-[40px] text-[16px] font-normal border-[#216DDE] border-1 bg-[#E9F0FC] rounded-lg disabled:bg-grey mt-[10px]"
      //         onClick={handleAuthWithAadhar}
      //       >
      //         Authenticate With Aadhar
      //       </button>
      //       <form id="authPageForm" method="post" action="https://esignuat.vsign.in/esp/authpage" className="hidden">
      //         <input type="text" id="txnref" name="txnref" ref={vsignFormInputRef} />
      //         <input type="submit" value="Submit" ref={vsignFormRefBtn} />
      //       </form>
      //     </>
      //   );
      //   break;
      case 'text_sign':
        // return <FreeTextSign />;
        return <EsignFreeTextSign />;
      case 'use_your_sign':
        return <EsignUseMySignWithTemplate />;
      default:
        break;
    }
  };

  const authenticateWithAadhar = () => {
    return (
      <>
        <label className="text-[14px] font-[600] text-[#030712] mt-[10px] mb-[5px]">Authenticate with Aadhar</label>
        <button
          className=" text-[#D82836] w-full h-[40px] text-[16px] font-[500] border-[#FCB729] border-1 bg-[white] rounded-lg disabled:bg-grey flex items-center justify-center gap-[10px]"
          onClick={handleAuthWithAadhar}
        >
          Authenticate With
          <img src={EsignAppAssets.AadharLogo} alt="logo" className="w-[40px]" />
        </button>
        <form id="authPageForm" method="post" action="https://esignuat.vsign.in/esp/authpage" className="hidden">
          <input type="text" id="txnref" name="txnref" ref={vsignFormInputRef} />
          <input type="submit" value="Submit" ref={vsignFormRefBtn} />
        </form>
      </>
    );
  };

  const showActionsBasedOnType = () => {
    console.log(selectedSign?.actionType, 'selectedSignData');
    if (selectedSign?.actionType === PlaceHolderTypes.sign) {
      return (
        <>
          {authenticateWithAadhar()}
          <div className="mt-[20px] w-full flex items-center justify-center text-[14px] font-[600] text-[#030712]">OR</div>
          <CommonDropdown
            items={allSignTypeList}
            className={'addSignPanel'}
            buttonLabel={esignSelectedSignType?.title || 'Select'}
            onSelect={handleOnSelect}
            dropTopTitle="Proceed via digiesign"
          />

          {showPreview()}
        </>
      );
    } else if (selectedSign?.actionType === PlaceHolderTypes.valuePlaced) {
      return <RemovePlaceholderValue />;
    } else if (selectedSign?.actionType === PlaceHolderTypes.initials) {
      return <EsignAddInitials />;
    } else if (selectedSign?.actionType === PlaceHolderTypes.name) {
      return <EsignAddName />;
    } else if (selectedSign?.actionType === PlaceHolderTypes.mobile) {
      return <EsignAddMobileNumber />;
    } else if (selectedSign?.actionType === PlaceHolderTypes.designation) {
      return <EsignAddDesignation />;
    } else if (selectedSign?.actionType === PlaceHolderTypes.email) {
      return <EsignAddEmail />;
    } else if (selectedSign?.actionType === PlaceHolderTypes.dateSigned) {
      return <EsignAddDate />;
    } else {
      return <></>;
    }
  };

  // const fetchUserSignature = async () => {
  //   try {
  //     dispatch(setEsignMainAppLoader(true));
  //     const { sign_document } = await getUserPreferences();
  //     if (sign_document) {
  //       console.log(sign_document, 'sign_document');
  //       const useYourSign = {
  //         title: 'Use your sign',
  //         type: 'use_your_sign',
  //         img: `data:image/png;base64,${sign_document}`
  //       };
  //       setAllSignTypeList((state) => {
  //         return [...state, useYourSign];
  //       });
  //     }
  //   } catch (error) {
  //   } finally {
  //     dispatch(setEsignMainAppLoader(false));
  //   }
  // };

  useEffect(() => {
    if (signature) {
      const useYourSign = {
        title: 'Use your sign',
        type: 'use_your_sign',
        img: signature
      };
      console.log(signature, 'signatureData');
      setAllSignTypeList((state) => {
        const isExist = state.find((item) => item.type === 'use_your_sign');
        if (!isExist) {
          return [...state, useYourSign];
        } else {
          return [...state];
        }
      });
    }
  }, [signature]);
  console.log(usersNodes, 'usersNodes');

  console.log(isAllThingsSubmitted, 'isAllThingsSubmitted');
  return (
    <div
      className="h-full w-full overflow-auto"
      style={{
        maxHeight: 'calc(100vh - 160px)'
      }}
    >
      <div>
        <EsignAvatar initials={getUserFullNameInitials()} fullName={usersNodes?.[0]?.userFullName} bgColor={usersNodes?.[0]?.color} />
        {usersNodes?.[0]?.userFullName && (
          <>
            {!isAllThingsSubmitted && !canvas?.getActiveObject() && (
              <p className="font-[400] text-[14px] text-[#6B7280] pt-[16px]">Click on the Placeholder to complete action</p>
            )}
          </>
        )}
      </div>
      {showActionsBasedOnType()}
    </div>
  );
};

export default RightSideSignNPlaceholderValueAdd;
