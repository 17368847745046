import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setEsignMainAppLoader } from 'store/esignMainSlice';

const useLoadTailwindCss = () => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    // Dynamically import SCSS file when component mounts
    import('../assets/scss/pages/newDashboard.scss')
      .then(() => {
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error('Error loading Dashboard SCSS:', error);
      })
      .finally(() => {});
  }, []); // Run effect only once when component mounts
  return {
    isLoaded
  };
};
export default useLoadTailwindCss;
