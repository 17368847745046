import React, { useEffect, useState } from 'react';

import moment from 'moment-timezone';
import { useTimezone } from 'context/TimezoneContext';
import CommonDropdown from '../Dropdowns/CommonDropdown';

const TimezoneDropdown = () => {
  const { timezone, changeTimezone } = useTimezone();
  const [zones, setZones] = useState([]);

  useEffect(() => {
    // Get all time zones
    setZones(moment.tz.names());
  }, []);

  const list = zones.map((item) => {
    return { title: item, type: item };
  });

  return (
    <>
      {/* <CommonDropdown
        items={list}
        className={'addSignPanel'}
        buttonLabel={'Select TimeZone that you are currently in'}
        // onSelect={handleOnSelect}
        dropTopTitle="Time Zone"
      /> */}
      <select value={timezone} onChange={(e) => changeTimezone(e.target.value)} className="rounded-[6px] border-[#D1D5DB] border mt-[10px]">
        {zones.map((zone) => (
          <option key={zone} value={zone}>
            {zone}
          </option>
        ))}
      </select>
    </>
  );
};

export default TimezoneDropdown;
