import React from 'react';
import { useSelector } from 'react-redux';

const useGetAllFunctionsForRecipientUnAuth = () => {
  const uploadedDocumentAllData = useSelector((state) => state.esignMain.uploadedDocumentAllData);

  return {
    documentData: uploadedDocumentAllData
  };
};

export default useGetAllFunctionsForRecipientUnAuth;
