import React, { useEffect, useState } from 'react';
import { ProfileSideBar } from './ProfileSideBar';
import { useAccount } from 'context/AccountProvider';
import EsignAppSecondaryButton from 'app/components/Buttons/EsignAppSecondaryButton';
import EsignAppPrimaryButton from 'app/components/Buttons/EsignAppPrimaryButton';
import { useForm } from 'react-hook-form';
import AccountSettingForm from './AccountSettingForm';
import { toast } from 'react-toastify';
import { accountSettingValidationSchema } from './accountsetting.const';
import { updateAccountSetting } from 'services/eSignService';
import { setEsignMainAppLoader } from 'store/esignMainSlice';
import { useDispatch } from 'react-redux';
import TimezoneDropdown from 'app/components/Timezone/TimezoneDropdown';
const FieldValueWithLabel = ({ label, value, containerClass }) => {
  return (
    <div className={containerClass}>
      <label className="block font-[600] text-[14px] text-[#030712] mb-[10px]">{label}</label>
      <p className="font-[500] text-[#6B7280] text-[14px] ">{value}</p>
    </div>
  );
};

const PersonalSettings = () => {
  const { userDetails } = useAccount();
  const { email, first_name, last_name, phone } = userDetails;
  const [isEdit, setIsEdit] = useState(false);
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState('');
  const [updatedPassword, setUpdatedPassword] = useState('');

  const [formData, setFormData] = useState({
    phone: '',
    oldPassword: '',
    newPassword: ''
  });

  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const validateForm = async () => {
    try {
      await accountSettingValidationSchema.validate(formData, { abortEarly: false });
      setErrors({}); // Clear any existing errors
      return true; // Validation passed
    } catch (err) {
      const newErrors = err.inner.reduce((acc, curr) => {
        acc[curr.path] = curr.message;
        return acc;
      }, {});
      const errorInArray = Object.keys(newErrors);
      if (errorInArray.length > 0) {
        toast.error(newErrors[errorInArray[0]]);
      }
      console.log(newErrors, errorInArray, 'err.inner');
      setErrors(newErrors); // Set errors state
      return false; // Validation failed
    }
  };

  const handleSubmit = async () => {
    // Validate the form data
    const isValid = await validateForm();
    if (isValid) {
      // Handle form submission (e.g., send data to API)
      const payload = {};
      if (formData.phone) {
        payload.phone_number = formData.phone;
      }
      if (formData.newPassword && formData.oldPassword) {
        payload.old_password = formData.oldPassword;
        payload.new_password = formData.newPassword;
      }
      const data = await updateAccountSetting(payload, (value) => {
        dispatch(setEsignMainAppLoader(value));
      });
      if (data) {
        toast.success('Data updated successfully!');
      }

      console.log('Form Submitted:', formData);
    } else {
      // toast.error('Please fill all the required fields');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, 'onChange');
    // Only allow changes to the phone number part after +91
    if (name === 'phone') {
      setFormData((prevState) => ({
        ...prevState,
        phone: value
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  useEffect(() => {
    setFormData((state) => {
      return {
        ...state,
        phone
      };
    });
  }, [userDetails]);

  console.log(formData, errors, 'updatedPhoneNumber');
  return (
    <>
      <ProfileSideBar>
        <div className="w-full h-full">
          <div className="mt-[20px]">
            <h2 className="text-[#030712] text-[20px] font-[700]">Personal Settings(Optional)</h2>
            <h4 className="text-[#030712] font-[600] text-[14px] mt-[20px] mb-[6px]">Time Zone</h4>

            <TimezoneDropdown />
          </div>
        </div>
      </ProfileSideBar>
    </>
  );
};

export default PersonalSettings;
