import React, { useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import toImg from 'd3-svg-to-png';
import { currentTimeInUtc, formatDateTime } from 'utils/esign.helper';
import { useAccount } from 'context/AccountProvider';
import html2canvas from 'html2canvas';
import { ReactSVG } from 'react-svg';

const CustomSignTemplate = ({ imageUrl, width = 353, height = 140, cb }) => {
  const currentDate = new Date();
  const dateData = formatDateTime(currentDate);
  const svgRef = useRef(null);

  const finalDate = `${dateData.withYearWithSlash} at ${dateData.time} ${currentTimeInUtc(currentDate)}`;
  const [svgUrl, setSvgUrl] = useState('');

  console.log(finalDate, 'finalDate');
  const { userDetails } = useAccount();
  console.log(userDetails, 'userDetails');
  const svg = `<svg width="${width}" height="${height}" viewBox="0 0 195 48" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<rect width="195" height="48" fill="none"/>
<path d="M-22288 -22554C-22288 -22555.1 -22287.1 -22556 -22286 -22556H20782C20783.1 -22556 20784 -22555.1 20784 -22554V10612C20784 10613.1 20783.1 10614 20782 10614H-22286C-22287.1 10614 -22288 10613.1 -22288 10612V-22554Z" fill="none"/>
<path d="M-22286 -22555H20782V-22557H-22286V-22555ZM20783 -22554V10612H20785V-22554H20783ZM20782 10613H-22286V10615H20782V10613ZM-22287 10612V-22554H-22289V10612H-22287ZM-22286 10613C-22286.6 10613 -22287 10612.6 -22287 10612H-22289C-22289 10613.7 -22287.7 10615 -22286 10615V10613ZM20783 10612C20783 10612.6 20782.6 10613 20782 10613V10615C20783.7 10615 20785 10613.7 20785 10612H20783ZM20782 -22555C20782.6 -22555 20783 -22554.6 20783 -22554H20785C20785 -22555.7 20783.7 -22557 20782 -22557V-22555ZM-22286 -22557C-22287.7 -22557 -22289 -22555.7 -22289 -22554H-22287C-22287 -22554.6 -22286.6 -22555 -22286 -22555V-22557Z" fill="transparent" fill-opacity="0.1"/>
<path d="M-12620 -6036C-12620 -6037.1 -12619.1 -6038 -12618 -6038H12999C13000.1 -6038 13001 -6037.1 13001 -6036V5439C13001 5440.1 13000.1 5441 12999 5441H-12618C-12619.1 5441 -12620 5440.1 -12620 5439V-6036Z" fill="none"/>
<path d="M-12618 -6037H12999V-6039H-12618V-6037ZM13000 -6036V5439H13002V-6036H13000ZM12999 5440H-12618V5442H12999V5440ZM-12619 5439V-6036H-12621V5439H-12619ZM-12618 5440C-12618.6 5440 -12619 5439.55 -12619 5439H-12621C-12621 5440.66 -12619.7 5442 -12618 5442V5440ZM13000 5439C13000 5439.55 12999.6 5440 12999 5440V5442C13000.7 5442 13002 5440.66 13002 5439H13000ZM12999 -6037C12999.6 -6037 13000 -6036.55 13000 -6036H13002C13002 -6037.66 13000.7 -6039 12999 -6039V-6037ZM-12618 -6039C-12619.7 -6039 -12621 -6037.66 -12621 -6036H-12619C-12619 -6036.55 -12618.6 -6037 -12618 -6037V-6039Z" fill="transparent" fill-opacity="0.1"/>
<rect x="-936" y="-669" width="1152" height="738" fill="transparent"/>
<rect x="8" y="6" width="187" height="35" fill="url(#pattern0_1_6)"/>
<path d="M2 3.08333C2 1.93274 2.99492 1 4.22222 1H6V2.04167H4.22222C3.60857 2.04167 3.11111 2.50804 3.11111 3.08333V6H2V3.08333Z" fill="black"/>
<path d="M2 43.9167C2 45.0673 2.99492 46 4.22222 46H6V44.9583H4.22222C3.60857 44.9583 3.11111 44.492 3.11111 43.9167V41H2V43.9167Z" fill="black"/>
<text transform="translate(0 41) rotate(-90)" fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="5" font-weight="bold" letter-spacing="0em"><tspan x="0.26416" y="3.63636">DIGI-EBOOKS</tspan></text>
<text fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="6" font-weight="bold" letter-spacing="0em"><tspan x="8.16357" y="3.63636">Esigned</tspan></text>
<text fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="6" font-weight="bold" letter-spacing="0em"><tspan x="29" y="3.63636"> on ${finalDate}</tspan></text>
<text fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="6" font-weight="bold" letter-spacing="0em"><tspan x="8" y="46.6364">${userDetails?.username}</tspan></text>
<defs>
<pattern id="pattern0_1_6" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlink:href="#image0_1_6" transform="matrix(0.0014509 0 0 0.00775194 -0.00636322 0)"/>
</pattern>
<image id="image0_1_6" height="120" width="400" x="8"  xlink:href="${imageUrl}" preserveAspectRatio="xMinYMid meet" />
</defs>
</svg>

`;

  console.log(svg, 'popSvgData');
  useEffect(() => {
    if (svg) {
      // Create a blob URL from the SVG string
      const blob = new Blob([svg], { type: 'image/svg+xml' });
      setSvgUrl(URL.createObjectURL(blob));
    }
  }, [svg]);

  console.log(svgUrl, 'popSvgData');
  return (
    <>
      {/* <SVG
        ref={svgRef}
        src={svg}
        width={width}
        height={height}
        id="signSvgPreview"
        style={{
          // visibility: "hidden",
          // background: "transparent",
          fontFamily: 'Inter'
        }}
        onLoad={(src, isCached) => {
          toImg('#signSvgPreview', 'name', {
            scale: 10,
            format: 'png',
            quality: 10,
            download: false
          }).then((fileData) => {
            console.log(fileData, 'svgTemplate with image');
            console.log(imageUrl, 'svgTemplate without template');
            console.log(src, 'svgTemplate with srs');
            setTimeout(() => {
              cb(fileData);
            }, 1000);
            //do something with the data
          });
        }}
      /> */}
      <ReactSVG
        afterInjection={() => {
          const selector = document.querySelector('.digiesignWithTemplate');
          console.log(selector, 'svgTemplate with image---2');
          if (selector !== null) {
            toImg('.digiesignWithTemplate', 'name', {
              scale: 10,
              format: 'png',
              quality: 10,
              download: false
            }).then((fileData) => {
              console.log(fileData, 'svgTemplate with image');
              console.log(imageUrl, 'svgTemplate without template');
              console.log(svg, 'svgDtaaty');
              cb(fileData);
              //do something with the data
            });
          }

          console.log(svg, 'svgTemplate');
        }}
        beforeInjection={(svg) => {
          svg.classList.add('digiesignWithTemplate');
          svg.setAttribute('style', 'font-family: Inter');
        }}
        className="wrapper-class-name bg-transparent"
        desc="Description"
        evalScripts="always"
        // fallback={() => <span>Error!</span>}
        httpRequestWithCredentials={true}
        // loading={() => <span>Loading</span>}
        onClick={() => {
          console.log('wrapper onClick');
        }}
        onError={(error) => {
          console.error(error);
        }}
        renumerateIRIElements={false}
        src={svgUrl}
        title="Title"
        useRequestCache={false}
        wrapper="span"
        style={{
          fontFamily: 'Inter',
          background: 'transparent',
          width: '100%'
        }}
      />
    </>
  );
};

export default CustomSignTemplate;
