import React from 'react';

const PersonalSettingIcon = ({}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_9230_38813)">
        <path
          d="M12 6C13.2426 6 14.25 4.99264 14.25 3.75C14.25 2.50736 13.2426 1.5 12 1.5C10.7574 1.5 9.75 2.50736 9.75 3.75C9.75 4.99264 10.7574 6 12 6Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.65592 9.64688C2.55998 9.14063 2.91998 7.5 4.12467 7.5H19.8747C21.0812 7.5 21.4412 9.14063 20.3434 9.64688L14.9997 12L17.1437 20.1497C17.2648 20.4193 17.2748 20.7258 17.1717 21.0028C17.0686 21.2798 16.8607 21.5052 16.5928 21.63C16.3249 21.7549 16.0186 21.7694 15.7401 21.6703C15.4616 21.5711 15.2334 21.3664 15.1047 21.1003L11.9997 15.75L8.89467 21.1003C8.76595 21.3664 8.53769 21.5711 8.25922 21.6703C7.98076 21.7694 7.67447 21.7549 7.40657 21.63C7.13868 21.5052 6.9307 21.2798 6.82759 21.0028C6.72449 20.7258 6.73455 20.4193 6.8556 20.1497L8.99967 12L3.65592 9.64688Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9230_38813">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PersonalSettingIcon;
