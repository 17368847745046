import HeaderHelpIcon from 'app/assets/icons/HeaderHelpIcon';
import HeaderMoreMenuIcon from 'app/assets/icons/HeaderMoreMenuIcon';
import HeaderNotificationIcon from 'app/assets/icons/HeaderNotificationIcon';
import classNames from 'classnames';
import UserMenuDropdownHeader from 'layout/MainLayoutNewUI/Dropdown';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import EsignAppLogo from '../EsignAppLogo';
import NotifcationDrawer from '../Drawer/NotifcationDrawer';
import { UserHeaderAvatarMenu } from 'layout/MainLayoutNewUI/Dropdown/UserHeaderAvatarMenu';
const RightSideAction = [
  { name: 'Help', src: HeaderHelpIcon, type: 'help', path: '/faqs' },
  { name: 'Notification', src: HeaderNotificationIcon, type: 'notification' }
];

const MenuList = [
  { title: 'Home', path: '/dashboard/home' },
  { title: 'Documents', path: '/dashboard/documents/inbox' }
];

const EsignAppHeader = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedHeaderAction, setSelectedHeaderAction] = useState(null);
  return (
    <>
      <div className="h-[64px] bg-white w-full flex items-center px-4 justify-between border border-[#D1D9E0]">
        <section className="flex items-center h-[100%] p-0 gap-[12px]">
          {/* <button className="flex-none">
            <HeaderMoreMenuIcon />
          </button> */}
          <EsignAppLogo />
        </section>
        <section className="flex items-center gap-[12px] h-full">
          {MenuList.map((item, i) => {
            // const isActive =
            //   pathname == '/dashboard/create-document' && item?.path == '/dashboard/documents/inbox' ? true : pathname === item.path;
            let isActive = pathname === item.path;
            if (pathname.includes('documents')) {
              isActive = pathname.includes('documents') && item.path.includes('documents');
            }
            console.log(isActive, pathname, item, 'isActiveNo');
            return (
              <div
                key={i}
                className={classNames(
                  'cursor-pointer text-[16px] font-[600] h-full text-center flex flex-col items-center justify-center p-[10px]',
                  { 'text-[#9CA3AF] ': !isActive },
                  { 'text-[#181818] border-b-[2px] border-[#3B82F6]': isActive }
                )}
                onClick={() => {
                  // navigate(item.path)
                  window.location.href = item.path;
                }}
              >
                <span className="text-center">{item?.title}</span>
              </div>
            );
          })}
        </section>
        <section className="flex items-center gap-3">
          {RightSideAction.map((item) => {
            return (
              <button
                key={item.name}
                className="flex-none"
                onClick={() => {
                  if (item.path) {
                    navigate(item.path);
                  } else {
                    setSelectedHeaderAction(item?.type);
                  }
                }}
              >
                <item.src />
              </button>
            );
          })}
          {selectedHeaderAction === 'notification' && <NotifcationDrawer onClose={() => setSelectedHeaderAction(null)} />}

          {/* <UserMenuDropdownHeader /> */}
          <UserHeaderAvatarMenu />
        </section>
      </div>
    </>
  );
};

export default EsignAppHeader;
