import { updateTimezoneInLocalstorage } from 'context/TimezoneContext';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserPreferences } from 'services/eSignService';
import { setEsignMainAppLoader } from 'store/esignMainSlice';

const useGetUserPreferences = () => {
  const dispatch = useDispatch();
  const [userPrefrences, setUserPrefrences] = useState({ signature: '', initials: '', timeZone: '' });
  const getUserSign = async () => {
    dispatch(setEsignMainAppLoader(true));
    try {
      const data = await getUserPreferences();
      if (data?.user_id) {
        const updatedValue = {};
        if (typeof data?.initial_file === 'string') {
          updatedValue['initials'] = `data:image/png;base64,${data?.initial_file}`;
        }
        if (typeof data?.signature_file === 'string') {
          updatedValue['signature'] = `data:image/png;base64,${data?.signature_file}`;
        }
        if (typeof data?.time_zone === 'string') {
          updatedValue['timeZone'] = data?.time_zone;
          updateTimezoneInLocalstorage(data?.time_zone);
        }
        setUserPrefrences(updatedValue);
      }
    } catch (error) {
    } finally {
      dispatch(setEsignMainAppLoader(false));
    }
  };
  useEffect(() => {
    getUserSign();
  }, []);
  console.log(userPrefrences, 'userPrefrences');
  return {
    ...userPrefrences,
    refetch: getUserSign
  };
};

export default useGetUserPreferences;
